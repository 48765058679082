export const handleError = (error) => {
    let errorMessage = '';

    if (error.response) {
        const status = error.response.status;

        switch (status) {
            case 400:
                errorMessage = 'Your request contains invalid data. Please verify the input and try again.';
                break;
            case 403:
                errorMessage = 'Access denied. You do not have permission to perform this action.';
                break;
            case 404:
                errorMessage = "Sorry, we couldn't find what you were looking for.";
                break;
            case 500:
                errorMessage = "An unexpected error occurred on our server. We're on it! Please try again shortly.";
                break;
            default:
                errorMessage = error.response.data.message || 'Something went wrong on the server. Please try again later.';
                break;
        }
    } else if (error.request) {
        errorMessage = 'No response received from the server. Please check your network connection.';
    } else {
        errorMessage = error.message || 'An unexpected error occurred. Please try again.';
    }

    return errorMessage;
};