import React, { useState, useEffect, useMemo } from "react";
import { Form } from "react-bootstrap";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import InfoIcon from "@mui/icons-material/Info";
import Popper from "@mui/material/Popper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import S3 from "react-aws-s3";
import Fade from "@mui/material/Fade";
import Loadingbutton from "@mui/lab/LoadingButton";

import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { styled, alpha } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import ArifactInfo from "./ArtifactInfo";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Assignuser12 from "../../AssignuserModels/ArtifactAssignusermodel";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Artifactpopup from "./Artifactpopup";
import AttachFileIcon from "@mui/icons-material/FilePresent";
import Badge from "@mui/material/Badge";
import TaskAltIcon from "@mui/icons-material/InsertDriveFile";
import useWindowDimensions from "../../../useWindowDimensions";
import { deepOrange, deepPurple } from "@mui/material/colors";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import cookie from "react-cookies";
import Skeleton from "@mui/material/Skeleton";

import { decryption } from "../../../auth/utilities";
import {
  Avatar,
  Backdrop,
  Dialog,
  DialogTitle,
  Drawer,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Checkbox,
  Autocomplete
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { blue } from "@mui/material/colors";

window.Buffer = window.Buffer || require("buffer").Buffer;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 200,
  bgcolor: "background.paper",
  borderRadius: "5px",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const style12 = {
  position: "absolute",
  color: "#001b2b",
  top: "50%",
  left: "80%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 170,
    maxWidth: "100%",
    textOoverflow: "ellipsis",

    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
function Artifactlistingpage() {
  const subdomain = window.location.host.split(".")[0];
  const [checkList, setCheckList] = useState([]);
  const [question, setQuestion] = useState(null);
  const [userList, setUserList] = useState([]);
  const [openid, setopenid] = useState();
  const [loading, setLoading] = React.useState(false);
  const [gaps, setgaps] = useState([]);
  const { height, width } = useWindowDimensions();
  const [open, setOpen] = React.useState(false);
  const [modalopen, setmodalOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setmodalOpen(false);
  const [Open1, setOpen1] = useState(false);
  const [SubArtifact, setSubArtifact] = useState([]);
  const [Loading, SetLoading] = React.useState(true);
  const [uploading, setUploading] = useState(false); // Tracks whether the file is being uploaded
  const [uploadComplete, setUploadComplete] = useState(false); 
  const handlemodalOpen = () => setmodalOpen(true);
  const handlemodalClose = () => setmodalOpen(false);
  const [S3filelink0, setS3filelink0] = React.useState(null);
  const [selectedFileName, setSelectedFileName] = useState(""); // State to hold the file name

  const config0 = {
    bucketName: "whizzc-artifact",
    // dirName: process.env.REACT_APP_DIR_NAME /* optional */,
    region: "us-east-1",
    accessKeyId: "AKIATUEBG6QGX4BZS2SE",
    secretAccessKey: "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",
    s3Url: "https://whizzc-artifact.s3.amazonaws.com",
  };
  const handleUpload0 = (file) => {
    setUploading(true);
    setUploadComplete(false);
  
    if (!file) return;
  
    const newFileName = file.name.replace(/[^a-zA-Z0-9.]/g, "_");
    setSelectedFileName(file.name); // Store the selected file name
    const contentType = file.type || "application/octet-stream";
  
    const ReactS3Client = new S3(config0);
    ReactS3Client.uploadFile(file, newFileName, { contentType })
      .then((data) => {
        if (data.status === 204) {
          setS3filelink0(data.location);
          setUploading(false);
          setUploadComplete(true);
        } else {
          setUploading(false);
        }
      })
      .catch(() => {
        setUploading(false);
      });
  };
  

  console.log("l", userList);
  const toggleDrawer1 = (newOpen) => () => {
    setOpen1(newOpen);
  };
  const getQuestion = () => {
    fetch(
      process.env.REACT_APP_BACKEND +
      subdomain +
      "/" +
      (cookie.load("Language") || "en") +
      "/artifact/artifactmasterlist/",
      {
        credentials: "include",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setQuestion(data.artifact_master || []);
          setUserList(data.user_list);
          handlemodalClose();
          SetLoading(false);
        } else {
          setQuestion(null);
        }
      })
      .catch((err) => { });
  };
  const getSubArtifact = (e) => {
    fetch(
      process.env.REACT_APP_BACKEND +
      subdomain +
      "/" +
      (cookie.load("Language") || "en") +
      "/artifact/adddocument/" +
      e +
      "/",
      {
        credentials: "include",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setSubArtifact(data);
          setOpen1(true);
        } else {
          setSubArtifact(null);
        }
      })
      .catch((err) => { });
  };
  useEffect(async () => {
    getQuestion();
  }, []);
  let finalAns = Object.values(
    checkList.reduce(
      (acc, cur) => Object.assign(acc, { [cur.question_id]: cur }),
      {}
    )
  );


  const onClickshow = (e, f) => {
    if (f === openid) {
      `setopenid`(0);
    } else {
      setopenid(f);
    }
  };

  const parentFunction = (points, audit_header_id, audit_gap_id) => {
    setLoading(true);
    const requestOption = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": cookie.load("csrftoken"),
      },
      credentials: "include",
      body: JSON.stringify({
        user: points,
        modified_by: cookie.load("user"),
      }),
    };

    const response = fetch(
      process.env.REACT_APP_BACKEND +
      subdomain +
      "/" +
      (cookie.load("Language") || "en") +
      "/auditmodule/assignartifactuser/" +
      audit_header_id +
      "/" +
      audit_gap_id +
      "/",
      requestOption
    ).then(
      (res) => {
        if (res.status === 200) {
          getQuestion();
          setLoading(false);
        } else {
          setLoading(false);
        }
      },
      (error) => { }
    );
  };
  const asdf = (item) => {
    var numOfnull = 0;
    if (item.length) {
      for (var i = 0; i < item.length; i++) {
        if (item[i].artifact_path !== null) {
          numOfnull++;
        }
      }
    }

    var percent = (numOfnull / item.length) * 100;
    let finalAns = Math.round(percent);

    return finalAns;
  };
  const handleSubmit11 = (user) => {
    console.log("op", user.user);
    SetLoading(true);
    const requestOption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": cookie.load("csrftoken"),
      },
      credentials: "include",

      body: JSON.stringify({
        assigned_to: user.user,
      }),
    };
    const response = fetch(
      process.env.REACT_APP_BACKEND +
      subdomain +
      "/" +
      (cookie.load("Language") || "en") +
      "/artifact/assignartifactmaster/" +
      Artifact_master_id.artifact_master_id +
      "/",
      requestOption
    ).then(
      (res) => {
        if (res.status === 201 || 200) {
          res.json().then((json) => {
            getQuestion();
            handleClose3();
            setLoading(false);
          });
        } else {
          res.json().then((json) => {
            getQuestion();
            handleClose3();
            setLoading(false);
          });
        }
      },
      (error) => { }
    );
  };
  function SimpleDialog(props) {
    const { onClose, open } = props;

    const handleClose = () => {
      onClose();
    };
    const navigate = useNavigate();

    const handleListItemClick = (value) => {
      onClose(value);
    };

    return (
      <Dialog onClose={handleClose} open={open}>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={Loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle>Select User</DialogTitle>
        <List>
          <Box sx={{ pt: 0, height: 400, width: 300, overflow: "scroll" }}>
            {userList.map((user) => (
              <ListItem disableGutters key={user}>
                <ListItemButton onClick={() => handleSubmit11(user)}>
                  <ListItemAvatar>
                    <Avatar
                      alt={user.profile_name}
                      // src="/static/images/avatar/1.jpg"
                      sx={{ bgcolor: blue[100], color: blue[600] }}
                    >
                      {/* <PersonIcon /> */}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={user.profile_name} />
                </ListItemButton>
              </ListItem>
            ))}
          </Box>
          <ListItem disableGutters>
            <ListItemButton autoFocus onClick={() => navigate("/user&roles")}>
              <ListItemAvatar>
                <Avatar>
                  <AddIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Add User" />
            </ListItemButton>
          </ListItem>
        </List>
      </Dialog>
    );
  }
  const emails = ["username@gmail.com", "user02@gmail.com"];

  const [open3, setOpen3] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState({
    user: cookie.load("user"),
    profile_name: "Me",
    profile_photo: null,
    user_email: "test@whizzc.com",
  });
  console.log("lop", selectedValue);
  const [Artifact_master_id, setArtifact_master_id] = useState({});
  const handleClickOpen = () => {
    setOpen3(true);
  };

  const handleClose3 = (value) => {
    setOpen3(false);
    // setSelectedValue(value);
  };

  const handleSubmit121 = (event) => {
    event.preventDefault();
    setUploading(true); // Indicate the form is in the process of being submitted
  
    const requestOption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": cookie.load("csrftoken"),
      },
      credentials: "include",
      body: JSON.stringify({
        sub_artifact_name: event.target.Eve_name.value,
        artifact_path: S3filelink0,
        date_of_capture: event.target.date_of_capture.value,
        date_of_expiry: event.target.Expire_date.value,
      }),
    };
  
    fetch(
      process.env.REACT_APP_BACKEND +
        subdomain +
        "/" +
        (cookie.load("Language") || "en") +
        "/artifact/artifactupload/" +
        Artifact_master_id.artifact_master_id +
        "/",
      requestOption
    )
      .then((res) => {
        if (res.status === 200 || 201) {
          res.json().then(() => {
            setS3filelink0(null); // Reset the file link after a successful upload
            getQuestion(); // Reload the artifact list
            // Close the modal after the artifact listing is successfully reloaded
          });
        } else {
          setUploading(false); // Stop the loading state if an error occurs
        }
      })
      .catch(() => {
        setUploading(false); // Stop loading state if an error occurs
      });
  };


  const [selectedCompliance, setSelectedCompliance] = useState([]);
  const [selectedProcess, setSelectedProcess] = useState([]);
  const [selectedControl, setSelectedControl] = useState([]);
  const [selectedStandard, setSelectedStandard] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const safeData = question || [];

  // Compliance options
  const complianceOptions = useMemo(() => {
    const compliances = new Set();
    safeData.forEach((item) => {
      if (item.cpca && Array.isArray(item.cpca)) {
        item.cpca.forEach((c) => {
          if (c.compliance_name) {
            compliances.add(c.compliance_name);
          }
        });
      }
    });
    return Array.from(compliances);
  }, [safeData]);

  // Process options
  const processOptions = useMemo(() => {
    const processes = new Set();
    safeData.forEach((item) => {
      if (item.cpca && Array.isArray(item.cpca)) {
        item.cpca.forEach((c) => {
          if (c.process_name) {
            processes.add(c.process_name);
          }
        });
      }
    });
    return Array.from(processes);
  }, [safeData]);

  // Control options
  const controlOptions = useMemo(() => {
    const controls = new Set();
    safeData.forEach((item) => {
      if (item.cpca && Array.isArray(item.cpca)) {
        item.cpca.forEach((c) => {
          if (c.control_name) {
            controls.add(c.control_name);
          }
        });
      }
    });
    return Array.from(controls);
  }, [safeData]);

  // Standard options
  const standardOptions = useMemo(() => {
    return [
      ...new Set(
        safeData
          .flatMap((item) => item.standards?.map((s) => s.standard_name) ?? [])
          .filter((name) => name)
      ),
    ];
  }, [safeData]);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFileName(file.name); // Store the file name in state
    }
  };
  

  // Filtered data based on selections and search term
  const filteredData = useMemo(() => {
    return safeData.filter((item) => {
      const complianceMatch =
        selectedCompliance.length === 0 ||
        item.cpca?.some((c) => selectedCompliance.includes(c.compliance_name));

      const processMatch =
        selectedProcess.length === 0 ||
        item.cpca?.some((c) => selectedProcess.includes(c.process_name));

      const controlMatch =
        selectedControl.length === 0 ||
        item.cpca?.some((c) => selectedControl.includes(c.control_name));

      const standardMatch =
        selectedStandard.length === 0 ||
        item.standards?.some((s) => selectedStandard.includes(s.standard_name));

      const searchMatch =
        searchTerm === "" ||
        item.cpca?.some(
          (c) =>
            c.compliance_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            c.process_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            c.control_name?.toLowerCase().includes(searchTerm.toLowerCase())
        ) ||
        item.standards?.some((s) =>
          s.standard_name?.toLowerCase().includes(searchTerm.toLowerCase())
        );

      return complianceMatch && processMatch && controlMatch && standardMatch && searchMatch;
    });
  }, [
    safeData,
    selectedCompliance,
    selectedProcess,
    selectedControl,
    selectedStandard,
    searchTerm,
  ]);


  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={Loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div style={{ background: "#eeeeee", padding: "10px" }}>
        <div
          style={{
            height: height - 170,
            overflow: "hidden",
            overflowY: "scroll",
          }}
        >
          <div style={{}}>
            {question ? (
              question.length === 0 ? (
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginTop: "7%",
                  }}
                >
                  <a
                    style={{
                      textAlign: "center",
                      justifyContent: "center",
                      color: "#001b2b",
                    }}
                  >
                    No data found !
                  </a>
                </div>
              ) : (
                <div style={{ marginBottom: "20px" }}>
                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={modalopen}
                    onClose={handlemodalClose}
                    closeAfterTransition
                  >
                    <Box
                      sx={{
                        ...style,
                        // width: 400,
                        // height: 300,
                        color: "#000000",
                      }}
                    >
                      <Box>
                        <Box sx={style12}>
                          <div style={{ color: "#000" }}>
                            <Typography
                              id="modal-modal-title"
                              variant="h6"
                              component="h2"
                            >
                              {{ ar: "تقديم الأدلة", sp: "Spanish" }[
                                cookie.load("Language")
                              ] || "Upload Artifact"}
                            </Typography>
                            <Form onSubmit={handleSubmit121} style={{ display: "flex", flexDirection: "column", gap: 20 }}>
  <TextField
    defaultValue={Artifact_master_id.artifact_name}
    fullWidth
    name="Eve_name"
    label="Sub Artifact Name"
    variant="outlined"
    required
  />

  <label htmlFor="file-upload" style={{ cursor: "pointer", width: "100%" }}>
    <Button
      variant="contained"
      component="span"
      sx={{ color: "white", background: "#001b2b", width: "100%" }}
    >
      Upload File
    </Button>
  </label>
  <input
    id="file-upload"
    type="file"
    style={{ display: "none" }} // Hide default file input
    onChange={(e) => handleUpload0(e.target.files[0])} // Update file on change
  />
  
  {/* Display the selected file name */}
  <Typography variant="body2" style={{ marginTop: "10px", color: "#001b2b" }}>
    {selectedFileName ? `Selected File: ${selectedFileName}` : ""}
  </Typography>

  <Typography>
    {uploading === "error" ? (
      <span style={{ color: "red" }}>Error while uploading, please retry!</span>
    ) : uploading ? (
      <span style={{ color: "orange" }}>Please wait, uploading...</span>
    ) : S3filelink0 ? (
      <span style={{ color: "green" }}>File Uploaded</span>
    ) : (
      ""
    )}
  </Typography>

  <Box sx={{ display: "flex", flexDirection: "row", gap: "20px" }}>
    <TextField
      name="date_of_capture"
      fullWidth
      InputLabelProps={{ shrink: true }}
      type="date"
      label="Date of Capture"
      variant="outlined"
      required
    />
    <TextField
      required
      name="Expire_date"
      fullWidth
      InputLabelProps={{ shrink: true }}
      type="date"
      label="Expire Date"
      variant="outlined"
    />
  </Box>

  <div style={{ float: "right", display: "flex", justifyContent: "flex-end" }}>
    <Button
      onClick={() => {
        setS3filelink0(null);
        handlemodalClose();
      }}
      style={{ background: "#eeeeee", color: "#808080" }}
      variant="contained"
    >
      Cancel
    </Button>

    <Loadingbutton
      type="submit"
      loading={uploading}
      style={{
        background: uploading ? "#808080" : "#00304c",
        marginLeft: "10px",
        color: "#ffffff",
      }}
      disabled={uploading || !uploadComplete}
    >
      {uploading ? "Uploading..." : "Submit"}
    </Loadingbutton>
  </div>
</Form>


                          </div>
                        </Box>
                      </Box>
                    </Box>
                  </Modal>
                  <div>
                    {/* Search Box */}
                    <TextField sx={{ m: 1, width: '98%' }}
                      label="Search"
                      variant="outlined"
                      fullWidth
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <FormControl sx={{ m: 1, width: '20%' }}>
                      <InputLabel id="compliance-select-label">Compliance Name</InputLabel>
                      <Select
                        labelId="compliance-select-label"
                        label="Compliance Name"
                        id="compliance-select"
                        multiple
                        value={selectedCompliance}
                        onChange={(e) => {
                          const value = e.target.value;
                          console.log("Selected Compliance:", value); // Log selected compliance
                          setSelectedCompliance(typeof value === 'string' ? value.split(',') : value);
                        }}
                        renderValue={(selected) => selected.join(", ")}
                      >
                        {complianceOptions.map((name) => (
                          <MenuItem key={name} value={name}>
                            <Checkbox checked={selectedCompliance.includes(name)} />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl sx={{ m: 1, width: '20%' }}>
                      <InputLabel id="process-select-label">Process Name</InputLabel>
                      <Select
                        labelId="process-select-label"
                        label="Process Name"
                        id="process-select"
                        multiple
                        value={selectedProcess}
                        onChange={(e) => {
                          const value = e.target.value;
                          console.log("Selected Process:", value); // Log selected process
                          setSelectedProcess(typeof value === 'string' ? value.split(',') : value);
                        }}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              width: '20%',     // Set the width of the dropdown menu
                              maxHeight: 500,  // Set the max height of the dropdown menu
                            },
                          },
                        }}
                      >
                        {processOptions.map((name) => (
                          <MenuItem key={name} value={name}>
                            <Checkbox checked={selectedProcess.includes(name)} />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>


                    {/* <FormControl sx={{ m: 1, width: 300 }}>
  <InputLabel id="control-select-label">Control Name</InputLabel>
  <Select
    labelId="control-select-label"
    label="Control Name"
    id="control-select"
    multiple
    value={selectedControl}
    onChange={(e) => {
      const value = e.target.value;
      console.log("Selected Control:", value); // Log selected control
      setSelectedControl(typeof value === 'string' ? value.split(',') : value);
    }}
    renderValue={(selected) => selected.join(", ")}
  >
    {controlOptions.map((name) => (
      <MenuItem key={name} value={name}>
        <Checkbox checked={selectedControl.includes(name)} />
        <ListItemText primary={name} />
      </MenuItem>
    ))}
  </Select>
</FormControl> */}

                    <FormControl sx={{ m: 1, width: '55%' }}>
                      <InputLabel id="standard-select-label"></InputLabel>
                      <Autocomplete
                        multiple
                        id="standard-select"
                        options={standardOptions}
                        value={selectedStandard}
                        onChange={(event, newValue) => {
                          setSelectedStandard(newValue); // Update the selected values
                          console.log("Selected Standard:", newValue); // Log selected standard
                        }}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option} // Assuming `standardOptions` is an array of strings
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              sx={{ marginRight: 1 }}
                              checked={selectedStandard.indexOf(option) > -1}
                            />
                            {option}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Standard Name"
                          />
                        )}
                        sx={{
                          "& .MuiAutocomplete-popupIndicator": {
                            transform: "none", // Ensures that the dropdown opens below the input
                          },
                          "& .MuiAutocomplete-inputRoot": {
                            width: '50%',
                          },
                        }}
                        popupIcon={null} // Hide the popup icon if desired
                      />
                    </FormControl>


                    <Typography
                      variant="body1"
                      sx={{
                        m: 2,
                        color: 'black',
                        textAlign: 'right'
                      }}
                    >
                      Number of Artifacts Listed: {filteredData.length}
                    </Typography>

                  </div>
                  <div style={{}}>
                    <div
                      className="controlslist"
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '10px 0',
                        borderBottom: '1px solid #e0e0e0',  // Optional: to separate the header visually
                      }}
                    >
                      {/* S.NO Column */}
                      <a
                        style={{
                          color: "#001b2b",
                          marginLeft: "40px",
                          fontSize: "16px",
                          textAlign: "left",
                          flex: 1,   // Control width for alignment
                        }}
                      >
                        {{ ar: "اسم العملية", sp: "Spanish" }[cookie.load("Language")] || "S.NO"}
                      </a>

                      {/* Artifact Name Column */}
                      <a
                        style={{
                          color: "#000000",
                          fontSize: "16px",
                          fontWeight: "550",
                          textAlign: "left",
                          flex: 4,  // Adjust width to align with the items below
                        }}
                      >
                        Artifact Name
                      </a>

                      {/* Assign User Column */}
                      <div
                        style={{
                          fontSize: "16px",
                          color: "#000000",
                          textAlign: 'center',
                          flex: 2,    // Align to match the items below
                        }}
                      >
                        Assign User
                      </div>

                      {/* Upload Artifact Column */}
                      <div
                        style={{
                          fontSize: "16px",
                          color: "#000000",
                          textAlign: 'center',
                          flex: 2,    // Align to match the items below
                        }}
                      >
                        Upload Artifact
                      </div>
                    </div>
                  </div>



                  <SimpleDialog open={open3} onClose={handleClose3} />
                  {filteredData.map((item, index) => {
                    return (
                      <div key={index} style={{ marginTop: "20px" }}>
                        <div
                          className="controlslist"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          {/* Index */}
                          <a
                            style={{
                              color: "#001b2b",
                              marginLeft: "40px",
                              fontSize: "16px",
                              textAlign: "left",
                              flex: 1,   // Match flex with the S.NO column in the header
                            }}
                          >
                            {index + 1}
                          </a>

                          {/* Artifact Name and Description */}
                          <a
                            style={{
                              color: "#808080",
                              fontSize: "16px",
                              fontWeight: "550",
                              flex: 4,   // Match flex with the Artifact Name column in the header
                            }}
                          >
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <span style={{ fontWeight: 'bold', color: '#001b2b', fontSize: '16px' }}>
                                {item.artifact_name}
                              </span>
                              <span style={{ color: '#808080', marginTop: '5px' }}>
                                {item.artifact_description}
                              </span>
                            </div>
                          </a>

                          {/* Assign User Avatar */}
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',   // Center align the avatar
                              flex: 2,  // Match flex with Assign User column in the header
                            }}
                          >
                            <Box
                              onClick={() => {
                                handleClickOpen();
                                setArtifact_master_id(item);
                              }}
                              sx={{ cursor: "pointer", display: 'flex', alignItems: 'center' }}
                            >
                              <Avatar
                                alt={selectedValue?.profile_name || "User"}
                                sx={{
                                  bgcolor: deepPurple[500],
                                  height: "30px",
                                  width: "30px",
                                }}
                              />
                            </Box>
                          </div>

                          {/* Upload Button */}
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',   // Center align the upload button
                              flex: 2,   // Match flex with Upload Artifact column in the header
                            }}
                          >
                            <Button
                              variant="contained"
                              sx={{ color: "white", background: "#001b2b" }} 
                              onClick={() => {
                                handlemodalOpen();
                                setArtifact_master_id(item);
                              }}
                            >
                              Upload
                            </Button>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                </div>
              )
            ) : (
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={Loading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Artifactlistingpage;
