import CryptoJS from "crypto-js";

export function encryption(email) {
  const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_K);
  const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_IV);
  const encrypted = CryptoJS.AES.encrypt(email, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
  });
  return encrypted.toString();
}

export function decryption(encryptedData) {
  const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_K);
  const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_IV);

  try {
    const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
    });
    const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);
    return decryptedData;
  } catch (error) {
    // Handle decryption error here
    return null;
  }
}
