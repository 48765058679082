import PropTypes from "prop-types";
// @mui
import { styled, alpha } from "@mui/material/styles";
import cookie from "react-cookies";

import {
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  TextField,
  InputAdornment,
} from "@mui/material";
// component
import Iconify from "../../iconify";

// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 250,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": {
    width: 400,

    boxShadow: theme.customShadows,
  },
  "& .MuiOutlinedInput-notchedOutline legend": { display: "none" },

  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${alpha("#001b2b")} !important`,
  },
}));

// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
};

export default function UserListToolbar({
  numSelected,
  filterName,
  onFilterName,
  Search_bar_name,
}) {
  return (
    <StyledRoot
      sx={{
        ...(numSelected > 0 && {
          color: "primary.main",
          bgcolor: "primary.lighter",
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <StyledSearch
          style={{ marginLeft: "-10px" }}
          value={filterName}
          onChange={onFilterName}
          placeholder={
            Search_bar_name
              ? Search_bar_name
              : {
                  ar: "يبحث",
                  sp: "Spanish",
                }[cookie.load("Language")] || "Search"
          }
          startAdornment={
            <InputAdornment position="start">
              <Iconify
                icon="eva:search-fill"
                sx={{ color: "text.disabled", width: 20, height: 20 }}
              />
            </InputAdornment>
          }
        />
      )}

      {/* {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <Iconify color="red" icon="eva:trash-2-fill" />
          </IconButton>
        </Tooltip>
      ) : null} */}
    </StyledRoot>
  );
}
{
  /*<Tooltip title="Filter list">
          <IconButton>
            <Iconify icon="ic:round-filter-list" />
          </IconButton>
      </Tooltip>*/
}
