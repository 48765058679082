import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Card,
  CardContent,
  Typography,
  Drawer,
  Grid,
  Chip,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Checkbox,
  ListItemText,
  Modal,
  IconButton,
  Backdrop,
} from "@mui/material";
import cookie from "react-cookies";

import AWS from "aws-sdk";
import { Pie } from 'react-chartjs-2';

import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info"; // Import InfoIcon
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
const config = {
  bucketName: "whizzc-artifact",
  region: "us-east-1",
  accessKeyId: "AKIATUEBG6QGX4BZS2SE",
  secretAccessKey: "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",
  s3Url: "https://whizzc-artifact.s3.amazonaws.com",
};

AWS.config.update({
  region: config.region,
  accessKeyId: config.accessKeyId,
  secretAccessKey: config.secretAccessKey,
});

const s3 = new AWS.S3();
const reportS3 = new AWS.S3();


const style = {
  position: "absolute",
  color: "#001b2b",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 3,
  p: 4,
  display: "flex",
  flexDirection: "column",
};

const riskMatrixStyle = {
  position: "absolute",
  color: "#001b2b",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 3,
  p: 4,
};

const criteriaStyle = {
  position: "absolute",
  color: "#001b2b",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  height: "40%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 3,
  p: 4,
};

const impactStyle = {
  position: "absolute",
  color: "#001b2b",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  height: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 3,
  p: 4,
};

const likelihoodStyle = {
  position: "absolute",
  color: "#001b2b",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  height: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 3,
  p: 4,
};

function RiskRegister({ riskAssessmentId }) {
  const [risks, setRisks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedRisk, setSelectedRisk] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    risk_name: "",
    impact_c: "",
    impact_i: "",
    impact_a: "",
    likelihood: "",
    inherent_risk_value: "",
    inherent_risk: "",
    mitigation_plan: "",
    modified_impact_c: "",
    modified_impact_i: "",
    modified_impact_a: "",
    modified_likelihood: "",
    residual_risk_value: "",
    residual_risk: "",
    risk_observation: "",
    risk_identification: "",
    target_date: null,
    alias_name: null,
    artifact_capture_date: null,
    artifact_expiry_date: null,
    artifact_name: null,
    artifact_path: null,
    status: null,
  });
  const [categories, setCategories] = useState([]);
  const [assessment, setAssessment] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [selectedInherentRisk, setSelectedInherentRisk] = useState("");
  const [selectedResidualRisk, setSelectedResidualRisk] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedTreatment, setSelectedTreatment] = useState("");
  const [customMitigationPlan, setCustomMitigationPlan] = useState("");
  const [file, setFile] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [artifactLink, setArtifactLink] = useState("");
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [validationErrors, setValidationErrors] = useState({
    artifact_name: false,
    artifact_expiry_date: false,
    artifact_path: false,
  });
  const initialFormData = {
    risk_name: "",
    impact_c: "",
    impact_i: "",
    impact_a: "",
    likelihood: "",
    inherent_risk_value: "",
    inherent_risk: "",
    mitigation_plan: "",
    modified_impact_c: "",
    modified_impact_i: "",
    modified_impact_a: "",
    modified_likelihood: "",
    residual_risk_value: "",
    residual_risk: "",
    risk_observation: "",
    risk_identification: "",
    target_date: null,
    alias_name: null,
    artifact_capture_date: null,
    artifact_expiry_date: null,
    artifact_name: null,
    artifact_path: null,
    status: null,
  };

  const subdomain = window.location.host.split(".")[0];

  useEffect(() => {
    fetchRisks();
  }, [riskAssessmentId]);

  const fetchRisks = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}${subdomain}/${
          cookie.load("Language") || "en"
        }/riskassessment/riskregisterlist/${riskAssessmentId}`,
        { credentials: "include" }
      );
      const data = await response.json();
      setRisks(data.risk_register || []);
      setCategories(data.categories || []);
      setAssessment(data.assessment_details || []);
      if (data.assessment_details) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          risk_assessment_type: data.assessment_details.risk_assessment_type,
          is_submitted: data.assessment_details.is_submitted,
        }));
      }
    } catch (error) {
      console.error("Failed to fetch risks", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleCategoryFilterChange = (event) => {
    setCategoryFilter(event.target.value);
  };

  const handleInherentRiskChange = (event) => {
    setSelectedInherentRisk(event.target.value);
  };

  const handleResidualRiskChange = (event) => {
    setSelectedResidualRisk(event.target.value);
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleTreatmentChange = (event) => {
    setSelectedTreatment(event.target.value);
  };

  const handleDrawerOpen = (risk) => {
    setSelectedRisk(risk);
    setDrawerOpen(true);
    setIsEditing(false);
    setFormData({
      ...risk,
      alias_name: risk.alias_name || null,
      artifact_capture_date: risk.artifact_capture_date || null,
      artifact_expiry_date: risk.artifact_expiry_date || null,
      artifact_name: risk.artifact_name || null,
      artifact_path: risk.artifact_path || null,
      status: risk.status || null,
    });
    if (
      ![
        "Perform background verification",
        "Implement security training",
        "Conduct regular audits",
        "Establish incident response plan",
      ].includes(risk.mitigation_plan)
    ) {
      setCustomMitigationPlan(risk.mitigation_plan || "");
    } else {
      setCustomMitigationPlan("");
    }
  };

  const getChipColor = (riskLevel) => {
    if (!riskLevel) return "default";
    switch (riskLevel.toLowerCase()) {
      case "low":
        return "#a5d6a7"; // light green
      case "medium":
        return "#fff59d"; // light yellow
      case "high":
        return "#ef9a9a"; // light red
      default:
        return "default";
    }
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setSelectedRisk(null);
    setIsEditing(false);
    setFormData(initialFormData);
    setCustomMitigationPlan("");
    setFile(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        [name]: value,
      };
      const newStatus = determineStatus(updatedFormData);
      return {
        ...updatedFormData,
        status: newStatus,
      };
    });
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const artifactPath = file ? URL.createObjectURL(file) : "";

    setFile(file);
    const updatedFormData = {
      ...formData,
      artifact_path: artifactPath,
    };
    const newStatus = determineStatus(updatedFormData);
    setFormData({
      ...updatedFormData,
      status: newStatus,
    });
  };

  const uploadFileToS3 = async (file) => {
    const params = {
      Bucket: config.bucketName,
      Key: `${Date.now()}_${file.name}`,
      Body: file,
      ACL: "public-read",
    };

    try {
      const { Location } = await s3.upload(params).promise();
      return Location;
    } catch (error) {
      console.error("Error uploading file to S3", error);
      return null;
    }
  };

  const calculateInherentRiskValue = () => {
    const { impact_c, impact_i, impact_a, likelihood } = formData;
    return (
      likelihood *
      (parseInt(impact_c) + parseInt(impact_i) + parseInt(impact_a))
    );
  };

  const calculateResidualRiskValue = () => {
    const {
      modified_impact_c,
      modified_impact_i,
      modified_impact_a,
      modified_likelihood,
    } = formData;
    return (
      modified_likelihood *
      (parseInt(modified_impact_c) +
        parseInt(modified_impact_i) +
        parseInt(modified_impact_a))
    );
  };

  const determineRiskLevel = (value) => {
    if (value <= 9) return "Low";
    if (value > 9 && value <= 18) return "Medium";
    return "High";
  };

  const determineStatus = (data) => {
    const { treatment, justification, artifact_path, target_date } =
      data || formData;

    console.log("Determining status with the following values:");
    console.log("Treatment:", treatment);
    console.log("Justification:", justification);
    console.log("Artifact Path:", artifact_path);
    console.log("Target Date:", target_date);

    // Check for Closed status conditions
    if (
      (treatment === "ACCEPT" && justification) ||
      (treatment === "AVOID" && justification) ||
      (treatment === "TRANSFER" && justification) ||
      (treatment === "MITIGATE" && justification && artifact_path)
    ) {
      console.log("Status set to Closed");
      return "Closed";
    }

    // Check for In Progress status condition
    if (
      treatment === "MITIGATE" &&
      target_date &&
      (!artifact_path || !justification)
    ) {
      console.log("Status set to In Progress");
      return "In Progress";
    }

    // Default to Open status
    console.log("Status set to Open");
    return "Open";
  };

  useEffect(() => {
    const inherentRiskValue = calculateInherentRiskValue();
    setFormData((prevFormData) => ({
      ...prevFormData,
      inherent_risk_value: inherentRiskValue,
      inherent_risk: determineRiskLevel(inherentRiskValue),
    }));
  }, [
    formData.impact_c,
    formData.impact_i,
    formData.impact_a,
    formData.likelihood,
  ]);

  const handleSaveClick = async () => {
    setIsSubmitting(true);
    // Validation for artifact fields
    if (file || formData.artifact_path) {
      if (!formData.artifact_name || !formData.artifact_expiry_date) {
        alert("Please fill in the artifact name and expiry date.");
        setIsSubmitting(false);
        return;
      }
    }
    let artifactPath = formData.artifact_path;
    if (file) {
      artifactPath = await uploadFileToS3(file);
    }
    const updatedFormData = {
      ...formData,
      alias_name: formData.risk_name,
      mitigation_plan: customMitigationPlan || formData.mitigation_plan,
      inherent_risk_value: calculateInherentRiskValue(),
      inherent_risk: determineRiskLevel(calculateInherentRiskValue()),
      residual_risk_value: calculateResidualRiskValue(),
      residual_risk: determineRiskLevel(calculateResidualRiskValue()),
      artifact_path: artifactPath,
    };

    const csrfToken = cookie.load("csrftoken");

    try {
      await fetch(
        `${process.env.REACT_APP_BACKEND}${subdomain}/${
          cookie.load("Language") || "en"
        }/riskassessment/editriskregister/${
          selectedRisk.risk_register_risk_assessment_id
        }/`,
        {
          method: "PUT",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
          },
          body: JSON.stringify(updatedFormData),
        }
      );
      fetchRisks();
      handleDrawerClose();
    } catch (error) {
      console.error("Failed to update risk register", error);
    } finally {
      setIsSubmitting(false);
    }
    const newStatus = determineStatus(updatedFormData);
    setFormData((prevFormData) => ({
      ...updatedFormData,
      status: newStatus,
    }));
  };

  const handleCreateClick = () => {
    setSelectedRisk(null);
    setIsEditing(true);
    setDrawerOpen(true);
    setFormData(initialFormData);
    setCustomMitigationPlan("");
    setFile(null);
  };
  const handleSelectSuggestion = () => {
    if (formData.mitigation_plan) {
      const options = formData.mitigation_plan
        .split("\n")
        .filter((item) => item.trim() !== "");
      console.log("Split Options: ", options); // Log options to debug
      setDropdownOptions(options);
      setShowDropdown(true);
    } else {
      console.log("Mitigation plan is empty or null."); // Log for debugging
      setDropdownOptions([]);
      setShowDropdown(false);
    }
  };

  const handleOptionSelect = (event) => {
    const selected = event.target.value;
    const cleanedSelected = selected.replace(/^\d+\)\s*/, ""); // Remove leading number and bracket
    setSelectedOption(cleanedSelected);
    setFormData({ ...formData, applied_mitigation_plan: cleanedSelected });
    setShowDropdown(false);
  };

  const handleCreateSubmit = async () => {
    setIsSubmitting(true);

    // Validation for artifact fields
    if (file || formData.artifact_path) {
      if (!formData.artifact_name || !formData.artifact_expiry_date) {
        alert("Please fill in the artifact name and expiry date.");
        setIsSubmitting(false);
        return;
      }
    }

    let artifactPath = null;
    if (file) {
      artifactPath = await uploadFileToS3(file);
    }

    const updatedFormData = {
      ...formData,
      alias_name: formData.risk_name || null,
      mitigation_plan: customMitigationPlan || formData.mitigation_plan || null,
      inherent_risk_value: calculateInherentRiskValue(),
      inherent_risk: determineRiskLevel(calculateInherentRiskValue()),
      residual_risk_value: calculateResidualRiskValue(),
      residual_risk: determineRiskLevel(calculateResidualRiskValue()),
      target_date: formData.target_date || null,
      artifact_name: formData.artifact_name || null,
      artifact_expiry_date: formData.artifact_expiry_date || null,
      artifact_path: artifactPath,
    };

    const csrfToken = cookie.load("csrftoken");

    try {
      await fetch(
        `${process.env.REACT_APP_BACKEND}${subdomain}/${
          cookie.load("Language") || "en"
        }/riskassessment/customriskregister/${riskAssessmentId}/`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
          },
          body: JSON.stringify(updatedFormData),
        }
      );
      fetchRisks();
      handleDrawerClose();
    } catch (error) {
      console.error("Failed to create risk register", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleOpenArtifactModal = (link) => {
    setArtifactLink(link);
    setOpenModal(true);
  };

  const handleCloseArtifactModal = () => {
    setOpenModal(false);
    setArtifactLink("");
    setIsFullscreen(false);
  };

  const handleToggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const renderFileViewer = (link) => {
    const fileExtension = link.split(".").pop().toLowerCase();
    if (["pdf"].includes(fileExtension)) {
      return (
        <iframe
          src={link}
          title="Artifact"
          width="100%"
          height="100%"
          style={{ border: "none", flexGrow: 1 }}
        />
      );
    }
    if (["doc", "docx", "xls", "xlsx", "ppt", "pptx"].includes(fileExtension)) {
      return (
        <iframe
          src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
            link
          )}`}
          title="Artifact"
          width="100%"
          height="100%"
          style={{ border: "none", flexGrow: 1 }}
        />
      );
    }
    if (["png", "jpg", "jpeg", "gif"].includes(fileExtension)) {
      return (
        <img
          src={link}
          alt="Artifact"
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            objectFit: "contain",
            flexGrow: 1,
          }}
        />
      );
    }
    return <Typography variant="h6">Unsupported file type</Typography>;
  };

  const filteredRisks = risks.filter(
    (risk) =>
      risk.requirement_name
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase()) &&
      (categoryFilter.length === 0 ||
        categoryFilter.includes(risk.category_name)) &&
      (selectedInherentRisk === "" ||
        risk.inherent_risk === selectedInherentRisk) &&
      (selectedResidualRisk === "" ||
        risk.residual_risk === selectedResidualRisk) &&
      (selectedStatus === "" || risk.status === selectedStatus) &&
      (selectedTreatment === "" || risk.treatment === selectedTreatment)
  );

  const validateArtifactFields = () => {
    const { artifact_name, artifact_expiry_date, artifact_path } = formData;
    const errors = {
      artifact_name: false,
      artifact_expiry_date: false,
      artifact_path: false,
    };

    if (artifact_name || artifact_expiry_date || artifact_path) {
      if (!artifact_name) errors.artifact_name = true;
      if (!artifact_expiry_date) errors.artifact_expiry_date = true;
      if (!artifact_path) errors.artifact_path = true;
    }

    setValidationErrors(errors);
    return (
      !errors.artifact_name &&
      !errors.artifact_expiry_date &&
      !errors.artifact_path
    );
  };

  const [riskMatrixModalOpen, setRiskMatrixModalOpen] = useState(false);
  const [criteriaModalOpen, setCriteriaModalOpen] = useState(false);
  const [impactModalOpen, setImpactModalOpen] = useState(false);
  const [likelihoodModalOpen, setLikelihoodModalOpen] = useState(false);

  const handleOpenRiskMatrixModal = () => {
    setRiskMatrixModalOpen(true);
  };

  const handleCloseRiskMatrixModal = () => {
    setRiskMatrixModalOpen(false);
  };

  const handleOpenCriteriaModal = () => {
    setCriteriaModalOpen(true);
  };

  const handleCloseCriteriaModal = () => {
    setCriteriaModalOpen(false);
  };

  const handleOpenImpactModal = () => {
    setImpactModalOpen(true);
  };

  const handleCloseImpactModal = () => {
    setImpactModalOpen(false);
  };

  const handleOpenLikelihoodModal = () => {
    setLikelihoodModalOpen(true);
  };

  const handleCloseLikelihoodModal = () => {
    setLikelihoodModalOpen(false);
  };

  const [reportFile, setReportFile] = useState(null);
  const [reportGen, setReportGen] = useState(false);
  const { PDFDocument, rgb, StandardFonts } = require('pdf-lib');
  const bucketName = 'whizzc-policy-document';
  const fileName = 'Risk Assessment Report.pdf';
  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    
    return `${day}${getOrdinalSuffix(day)} ${month}, ${year}`;
  };

  useEffect(() => {
    const fetchFileFromS3 = async () => {
        try {
            const params = {
                Bucket: bucketName,
                Key: fileName,
            };
            const url = reportS3.getSignedUrl('getObject', params);
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const fileBlob = await response.blob();
            setReportFile(fileBlob);
        } catch (error) {
            console.error('Error fetching file from S3:', error);
        }
    };
  
    fetchFileFromS3();
  }, [bucketName, fileName]);

  const generateReport = async() => {
    // api/dev/en/riskassessment/riskregisterlist/risk_assessment_id/
    setReportGen(true)
    if (reportFile) {
      try {
        const pdfBytes = await reportFile.arrayBuffer();
        const pdfDoc = await PDFDocument.load(pdfBytes);
        const HelveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
        const firstPage = pdfDoc.getPages()[0];
        const { width, height } = firstPage.getSize();
        const reportDate = formatDate(new Date());
        const boldCustomFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

        let totalMitigate = 0;
        let totalAccept = 0;
        let totalInProgress = 0;

        filteredRisks.map((risk) => {
          if (risk.treatment === 'MITIGATE') {
            totalMitigate++;
          }
          if (risk.treatment === 'ACCEPT') {
            totalAccept++;
          }
          if (risk.status === 'In Progress') {
            totalInProgress++;
          }
        });

        const pieChartData = {
          labels: ["In Progress", "Accepted Risks", "Mitigated Risks"],
          datasets: [
            {
              data: [totalMitigate, totalAccept, totalInProgress],
              backgroundColor: [
                  "#6CE5E8",
                  "#41B8D5",
                  "#2D8BBA"
              ],
            },
          ],
        };

        const options_PieChart = {
          responsive: true,
          maintainAspectRatio: false, // Allow flexibility in the chart's aspect ratio
          layout: {
            padding: {
              top: 50,    // Increase padding as needed
              bottom: 50,
              left: 140,
              right: 140
            }
          },
          plugins: {
            legend: {
              display: true,
              labels: {
                color: 'white', // Set the legend font color here
              },
              align: 'center',
              fullSize: true,
              padding: 10,
            },
            tooltip: {
              enabled: true,
            },
            datalabels: {
              formatter: (value, context) => {
                const sum = context.chart.data.datasets[0].data.reduce((a, b) => a + b, 0);
                const percentage = ((value / sum) * 100).toFixed(2) + '%';
                const label = context.chart.data.labels[context.dataIndex];
                return `${label} ${percentage}`;
              },
              color: '#fff',
              backgroundColor: '#000',
              borderRadius: 3,
              anchor: 'end',
              align: 'end',
              offset: 10,
              clip: false, // Ensure labels are not clipped
              clamp: true, // Ensure labels stay within the chart area
            },
          },
        };

        //const PieChartImage = await generateChartImage(pdfDoc, Pie, { data: pieChartData, options: options_PieChart})
        
        firstPage.drawText(assessment.company_name, {
          x: 59,
          y: height - 480,
          font: HelveticaFont,
          color: rgb(1,1,1),
          size: 16,
        });
        firstPage.drawText(reportDate, {
          x: 415,
          y: height - 759,
          font: HelveticaFont,
          color:rgb(1,1,1),
          size: 16
        })
        
        const secondPage = pdfDoc.getPages()[1];
        const summaryText = `The risk assessment conducted for ${assessment.company_name} utilized the compliance software Whizzc to systematically identify, evaluate, and mitigate risks impacting IT systems, data management, and operations.This report, generated through Whizzc, highlights key vulnerabilities, assesses associated risks, and proposes targeted mitigations. It emphasizes the critical need to strengthen security protocols, ensure regulatory compliance, and safeguard essential assets.The recommendations provided are designed to enhance ${assessment.company_name}'s resilience against potential threats and ensure the continuity of operations.`
        const scopeText1 = `Timeframe: ${assessment.company_name}`
        const scopeText2 = `Areas Assessed: ${assessment.company_name}`
        const scopeText3 = `Exclusions: ${assessment.company_name}`
        const methodText = `The risk assessment for ${assessment.company_name} was conducted using a comprehensive and systematic approach facilitated by Whizzc.`
        const planText = `The methodology began with planning and scoping, where the objectives of the risk assessment were clearly defined to align with ${assessment.company_name}'s strategic goals.The scope was determined by identifying specific areas within IT systems, data management, and operations that were critical to business functions. Stakeholders were engaged early in the process to gather insights, ensure comprehensive coverage, and secure support for the assessment`
        
        secondPage.drawText(summaryText, {
          x: 46,
          y: height - 150,
          font: HelveticaFont,
          color: rgb(0,0,0),
          size: 12,
          lineHeight: 18,
          maxWidth: 500, wordBreaks: [" "]
        });
        secondPage.drawText(scopeText1, {
          x: 63,
          y: height - 437,
          font: HelveticaFont,
          color: rgb(0,0,0),
          size: 12,
        });
        secondPage.drawText(scopeText2, {
          x: 63,
          y: height - 453,
          font: HelveticaFont,
          color: rgb(0,0,0),
          size: 12,
        });
        secondPage.drawText(scopeText3, {
          x: 63,
          y: height - 469,
          font: HelveticaFont,
          color: rgb(0,0,0),
          size: 12,
        });
        secondPage.drawText(methodText, {
          x: 46,
          y: height - 530,
          font: HelveticaFont,
          color: rgb(0,0,0),
          size: 12,
          lineHeight: 18,
          maxWidth: 500, wordBreaks: [" "]
        });
        secondPage.drawText(planText, {
          x: 46,
          y: height - 610,
          font: HelveticaFont,
          color: rgb(0,0,0),
          size: 12,
          lineHeight: 18,
          maxWidth: 500, wordBreaks: [" "]
        });

        const thirdPage = pdfDoc.getPages()[2];
        const evaluationText = `Risk evaluation focused on prioritizing risks based on their calculated levels, highlighting those that posed the greatest threat to the organization. Each risk's acceptability was determined by comparing it against ${assessment.company_name}'s risk appetite and tolerance levels. The effectiveness of existing controls was assessed using Whizzc’s tools to identify any gaps or weaknesses.`
        const reportingText = `Finally, documentation and reporting were thoroughly managed using Whizzc. Detailed risk assessment reports highlighted key findings, risk levels, mitigation actions, and monitoring results. These reports were shared with relevant stakeholders to keep them informed about the risks and the measures taken to address them. The entire process was conducted in compliance with relevant regulatory requirements and industry standards, ensuring that ${assessment.company_name} effectively managed risks associated with its IT systems, data management, and operations, thereby enhancing overall resilience and ensuring the continuity of critical business functions`

        thirdPage.drawText(evaluationText, {
          x: 46,
          y: height - 300,
          font: HelveticaFont,
          color: rgb(0,0,0),
          size: 12,
          lineHeight: 18,
          maxWidth: 500, wordBreaks: [" "]
        });
        thirdPage.drawText(reportingText, {
          x: 46,
          y: height - 550,
          font: HelveticaFont,
          color: rgb(0,0,0),
          size: 12,
          lineHeight: 18,
          maxWidth: 500, wordBreaks: [" "]
        });

        const templatePageIndex = 5;
        const [sixthPage] = await pdfDoc.copyPages(pdfDoc, [templatePageIndex]);
        let currentPage = sixthPage;
        let currentRow = 1;
        const yPositions = [679, 625, 570, 516, 462, 407, 354, 299, 246, 191, 137];
        const newPageCheck = [];

        for (let i = 1; i * 11 <= 9999; i++) {
          newPageCheck.push((i * 11) + 1);
        }
      
        for (let i1 = 0; i1 < filteredRisks.length; i1++) {
          const { requirement_name: col1, category_name: col2 } = filteredRisks[i1];

          if (i1 === 0) {
            let updatedPageIndex = pdfDoc.getPageCount() - 5
            const [newPage] = await pdfDoc.copyPages(pdfDoc, [templatePageIndex]);
            pdfDoc.insertPage(updatedPageIndex, newPage);
            currentPage = pdfDoc.getPages()[updatedPageIndex];
          }
      
          if (newPageCheck.includes(currentRow)) {
            const [newPage1] = await pdfDoc.copyPages(pdfDoc, [templatePageIndex]);
            let modifiedPageIndex = pdfDoc.getPageCount() - 5
            pdfDoc.insertPage(modifiedPageIndex, newPage1);
            currentPage = pdfDoc.getPages()[modifiedPageIndex];
          }
      
          let yPosition = yPositions[currentRow - 1];
          if (currentRow > 11) yPosition = yPositions[(currentRow - 1) % yPositions.length];

          if (i1 === 0) {
            currentPage.drawText('RISK ASSESSMENT',{
              x: 46,
              y: height - 65,
              font: boldCustomFont,
              color: rgb(0,0,0),
              size: 14,
            })
            currentPage.drawText('Risk Identification',{
              x: 46,
              y: height - 82,
              font: boldCustomFont,
              color: rgb(0,0,0),
              size: 12,
            })
          }

          const risk_id = `Risk ${currentRow.toString()}`
          currentPage.drawText(risk_id, { x: 60, y: yPosition, size: 10, color: rgb(0,0,0) });
          currentPage.drawText(col1, { x: 115, y: yPosition, size: 10, color: rgb(0,0,0), maxWidth: 250, lineHeight: 12 });
          currentPage.drawText(col2, { x: 368, y: yPosition, size: 10, color: rgb(0,0,0), maxWidth: 200, lineHeight: 12 });
          currentRow++;

          if (i1 === (filteredRisks.length - 1)) currentRow = 1;
        }

        pdfDoc.removePage(templatePageIndex);
      
        const templatePageIndex1 = pdfDoc.getPageCount() - 4;
        const [seventhPage] = await pdfDoc.copyPages(pdfDoc, [templatePageIndex1]);
        let currentPage1 = seventhPage;
        let currentRow1 = 1;
        const yPositions1 = [647, 592, 537, 482, 427, 370, 315, 260, 205, 150];
        const newPageCheck1 = [];

        for (let i2 = 1; i2 * 10 <= 9999; i2++) {
          newPageCheck1.push((i2 * 10) + 1);
        }
      
        for (let i3 = 0; i3 < filteredRisks.length; i3++) {
          const { requirement_name: col1, likelihood: col2, impact_c: col3, inherent_risk_value: col4 } = filteredRisks[i3];

          if (i3 === 0) {
            let updatedPageIndex1 = pdfDoc.getPageCount() - 4
            const [newPage2] = await pdfDoc.copyPages(pdfDoc, [templatePageIndex1]);
            pdfDoc.insertPage(updatedPageIndex1, newPage2);
            currentPage1 = pdfDoc.getPages()[updatedPageIndex1];
          }
      
          if (newPageCheck1.includes(currentRow1)) {
            const [newPage3] = await pdfDoc.copyPages(pdfDoc, [templatePageIndex1 - 1]);
            let modifiedPageIndex1 = pdfDoc.getPageCount() - 4
            pdfDoc.insertPage(modifiedPageIndex1, newPage3);
            currentPage1 = pdfDoc.getPages()[modifiedPageIndex1];
          }
      
          let yPosition1 = yPositions1[currentRow1 - 1];
          if (currentRow1 > 10) yPosition1 = yPositions1[(currentRow1 - 1) % yPositions1.length];

          if (i3 === 0) {
            currentPage1.drawText('Risk Analysis',{
              x: 46,
              y: height - 64,
              font: boldCustomFont,
              color: rgb(0,0,0),
              size: 12,
            })
          }

          const risk_id1 = `Risk ${currentRow1.toString()}`
          currentPage1.drawText(risk_id1, { x: 58, y: yPosition1, size: 10, color: rgb(0,0,0) });
          currentPage1.drawText(col1, { x: 110, y: yPosition1, size: 10, color: rgb(0,0,0), maxWidth: 225, lineHeight: 12 });
          currentPage1.drawText(col2.toString(), { x: 370, y: yPosition1, size: 10, color: rgb(0,0,0), maxWidth: 200, lineHeight: 12 });
          currentPage1.drawText(col3.toString(), { x: 435, y: yPosition1, size: 10, color: rgb(0,0,0), maxWidth: 200, lineHeight: 12 });
          currentPage1.drawText(col4.toString(), { x: 495, y: yPosition1, size: 10, color: rgb(0,0,0), maxWidth: 200, lineHeight: 12 });
          currentRow1++;

          if (i3 === (filteredRisks.length - 1)) currentRow1 = 1;
        }

        pdfDoc.removePage(templatePageIndex1 - 1);

        const templatePageIndex2 = pdfDoc.getPageCount() - 3;
        const [eigthPage] = await pdfDoc.copyPages(pdfDoc, [templatePageIndex2]);
        let currentPage2 = eigthPage;
        let currentRow2 = 1;
        const yPositions2 = [665, 608, 551, 494, 437, 381, 324, 268, 211, 154];
        const newPageCheck2 = [];

        for (let i4 = 1; i4 * 10 <= 9999; i4++) {
          newPageCheck2.push((i4 * 10) + 1);
        }
      
        for (let i5 = 0; i5 < filteredRisks.length; i5++) {
          const { requirement_name: col1, treatment: col2, risk_owner: col3, status: col4 } = filteredRisks[i5];
          if (i5 === 0) {
            let updatedPageIndex2 = pdfDoc.getPageCount() - 3
            const [newPage4] = await pdfDoc.copyPages(pdfDoc, [templatePageIndex2]);
            pdfDoc.insertPage(updatedPageIndex2, newPage4);
            currentPage2 = pdfDoc.getPages()[updatedPageIndex2];
          }
      
          if (newPageCheck2.includes(currentRow2)) {
            const [newPage5] = await pdfDoc.copyPages(pdfDoc, [templatePageIndex2 - 1]);
            let modifiedPageIndex2 = pdfDoc.getPageCount() - 3
            pdfDoc.insertPage(modifiedPageIndex2, newPage5);
            currentPage2 = pdfDoc.getPages()[modifiedPageIndex2];
          }
      
          let yPosition2 = yPositions2[currentRow2 - 1];
          if (currentRow2 > 10) yPosition2 = yPositions2[(currentRow2 - 1) % yPositions2.length];

          if (i5 === 0) {
            currentPage2.drawText('Risk Mitigation and Treatment',{
              x: 46,
              y: height - 64,
              font: boldCustomFont,
              color: rgb(0,0,0),
              size: 12,
            })
          }

          const risk_id2 = `Risk ${currentRow2.toString()}`
          currentPage2.drawText(risk_id2, { x: 59, y: yPosition2, size: 10, color: rgb(0,0,0) });
          currentPage2.drawText(col1, { x: 107, y: yPosition2, size: 10, color: rgb(0,0,0), maxWidth: 190, lineHeight: 12 });
          currentPage2.drawText(col2, { x: 318, y: yPosition2, size: 10, color: rgb(0,0,0), maxWidth: 200, lineHeight: 12 });
          // currentPage2.drawText(col3, { x: 368, y: yPosition2, size: 10, color: rgb(0,0,0), maxWidth: 200, lineHeight: 12 });
          currentPage2.drawText(col4, { x: 478, y: yPosition2, size: 10, color: rgb(0,0,0), maxWidth: 200, lineHeight: 12 });
          currentRow2++;
          if (i5 === (filteredRisks.length - 1)) currentRow2 = 1;
        }

        pdfDoc.removePage(templatePageIndex2 - 1);

        const templatePageIndex3 = pdfDoc.getPageCount() - 2;
        const [ninthPage] = await pdfDoc.copyPages(pdfDoc, [templatePageIndex3]);
        let currentPage3 = ninthPage;
        let currentRow3 = 1;
        const yPositions3 = [670, 614, 558, 502, 445, 388, 332, 275, 218, 162];
        const newPageCheck3 = [];

        for (let i6 = 1; i6 * 10 <= 9999; i6++) {
          newPageCheck3.push((i6 * 10) + 1);
        }
      
        for (let i7 = 0; i7 < filteredRisks.length; i7++) {
          const { requirement_name: col1, residual_risk_value: col2, alias_name: col3 } = filteredRisks[i7];
          if (i7 === 0) {
            let updatedPageIndex3 = pdfDoc.getPageCount() - 2
            const [newPage6] = await pdfDoc.copyPages(pdfDoc, [templatePageIndex3]);
            pdfDoc.insertPage(updatedPageIndex3, newPage6);
            currentPage3 = pdfDoc.getPages()[updatedPageIndex3];
          }
      
          if (newPageCheck3.includes(currentRow3)) {
            const [newPage7] = await pdfDoc.copyPages(pdfDoc, [templatePageIndex3 - 1]);
            let modifiedPageIndex3 = pdfDoc.getPageCount() - 2
            pdfDoc.insertPage(modifiedPageIndex3, newPage7);
            currentPage3 = pdfDoc.getPages()[modifiedPageIndex3];
          }
      
          let yPosition3 = yPositions3[currentRow3 - 1];
          if (currentRow3 > 10) yPosition3 = yPositions3[(currentRow3 - 1) % yPositions3.length];

          if (i7 === 0) {
            currentPage3.drawText('RISK ASSESSMENT RESULTS',{
              x: 46,
              y: height - 60,
              font: boldCustomFont,
              color: rgb(0,0,0),
              size: 12,
            })
          }

          const risk_id3 = `Risk ${currentRow3.toString()}`
          currentPage3.drawText(risk_id3, { x: 62, y: yPosition3, size: 10, color: rgb(0,0,0) });
          currentPage3.drawText(col1, { x: 114, y: yPosition3, size: 10, color: rgb(0,0,0), maxWidth: 250, lineHeight: 12 });
          currentPage3.drawText(col2.toString(), { x: 402, y: yPosition3, size: 10, color: rgb(0,0,0), maxWidth: 200, lineHeight: 12 });
          currentRow3++;
          if (i7 === (filteredRisks.length - 1)) currentRow3 = 1;
        }

        pdfDoc.removePage(templatePageIndex3 - 1);

        const conclusionText = `This risk assessment has identified key vulnerabilities and proposed actionable mitigations to enhance our security posture. By implementing these recommendations, ${assessment.company_name} can significantly reduce potential risks and ensure operational continuity. Ongoing monitoring and regular reviews are essential to adapt to evolving threats and maintain robust security measures.`
        const conclusionPage = pdfDoc.getPages()[pdfDoc.getPageCount() - 1]

        // conclusionPage.drawImage(PieChartImage, {
        //   x: 80,
        //   y: conclusionPage.getHeight() - 560,
        //   width: 440,
        //   height: 380,
        // });

        conclusionPage.drawText(conclusionText, {
          x: 46,
          y: height - 631,
          font: HelveticaFont,
          color: rgb(0,0,0),
          size: 12,
          lineHeight: 18,
          maxWidth: 500, wordBreaks: [" "]
        });
  
        setTimeout(async () => {
          const pageCount = pdfDoc.getPageCount();
          const modifiedPdfBytes = await pdfDoc.save();
          const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });
          const downloadLink = document.createElement('a');
          downloadLink.href = URL.createObjectURL(blob);
          downloadLink.download = 'Risk-Assessment_Report.pdf';
          downloadLink.click();
          setReportGen(false);
        }, 2000);
      } catch (error) {
        console.error('Error modifying PDF:', error);
      }
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "80vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ flexShrink: 0 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            p: 1,
            m: 1,
            borderRadius: 1,
          }}
        >
          {" "}
          <Box gap={2} display={"flex"} flexDirection={"row"}>
            <Typography color="#000000" variant="h5">
              Risks
            </Typography>
            <Chip label={`Total Count: ${filteredRisks.length}`} />
          </Box>
          <Button
            onClick={handleCreateClick}
            variant="contained"
            sx={{
              color: "#ffffff",
              background: "#001b2b",
              marginBottom: "10px",
              marginLeft: "10px",
              zIndex: 11,
            }}
            aria-label="add"
          >
            Create Custom Risk
          </Button>
          <Button
            onClick={generateReport}
            variant="contained"
            sx={{
              color: "#ffffff",
              background: "#001b2b",
              marginBottom: "10px",
              marginLeft: "10px",
              zIndex: 11,
            }}
            aria-label="add"
          >
            Generate Report
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            p: 1,
            m: 1,
            borderRadius: 1,
            gap: 5,
          }}
        >
          <TextField
            fullWidth
            label="Search Risks"
            variant="outlined"
            value={searchQuery}
            onChange={handleSearch}
          />
          <FormControl fullWidth variant="outlined">
            <InputLabel>Category</InputLabel>
            <Select
              multiple
              value={categoryFilter}
              onChange={handleCategoryFilterChange}
              renderValue={(selected) => selected.join(", ")}
              label="Category"
            >
              {categories.map((category) => (
                <MenuItem
                  key={category.category_id}
                  value={category.category_name}
                >
                  <Checkbox
                    checked={
                      categoryFilter.indexOf(category.category_name) > -1
                    }
                  />
                  <ListItemText primary={category.category_name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Inherent Risk</InputLabel>
            <Select
              value={selectedInherentRisk}
              onChange={handleInherentRiskChange}
              label="Inherent Risk"
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              <MenuItem value="Low">Low</MenuItem>
              <MenuItem value="Medium">Medium</MenuItem>
              <MenuItem value="High">High</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Residual Risk</InputLabel>
            <Select
              value={selectedResidualRisk}
              onChange={handleResidualRiskChange}
              label="Residual Risk"
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              <MenuItem value="Low">Low</MenuItem>
              <MenuItem value="Medium">Medium</MenuItem>
              <MenuItem value="High">High</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Status</InputLabel>
            <Select
              value={formData.status || ""}
              onChange={handleStatusChange}
              label="Status"
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              <MenuItem value="Open">Open</MenuItem>
              <MenuItem value="In Progress">In Progress</MenuItem>
              <MenuItem value="Closed">Closed</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Treatment</InputLabel>
            <Select
              value={selectedTreatment}
              onChange={handleTreatmentChange}
              label="Treatment"
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              <MenuItem value="MITIGATE">Mitigate</MenuItem>
              <MenuItem value="TRANSFER">Transfer</MenuItem>
              <MenuItem value="AVOID">Avoid</MenuItem>
              <MenuItem value="ACCEPT">Accept</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </div>

      <div style={{ flexGrow: 1, minHeight: "0", overflowY: "auto" }}>
        <div style={{ minHeight: "100%", overflowY: "auto" }}>
          {filteredRisks.length ? (
            filteredRisks.map((risk, index) => (
              <Card
                key={risk.risk_register_risk_assessment_id}
                onClick={() => handleDrawerOpen(risk)}
                style={{ margin: "10px", cursor: "pointer" }}
              >
                <CardContent
                  style={{
                    background: "#f9f9f9",
                    padding: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="body1" style={{ fontSize: "16px" }}>
                        {"RISK "}
                        {index + 1}.{" "}
                        {risk.requirement_name === "Custom"
                          ? risk.alias_name
                          : risk.requirement_name}
                      </Typography>
                      <Chip
                        label={risk.category_name}
                        style={{
                          marginLeft: "10px",
                          fontSize: "12px",
                          height: "24px",
                        }} // Adjust the styles as needed
                      />
                    </Box>
                  </Box>
                  <Box>
                    {risk.treatment ? (
                      <>
                        <Chip
                          label={`Treatment: ${risk.treatment}`}
                          style={{ marginRight: "5px" }} // Adjust the margin as needed
                        />
                        {risk.treatment === "MITIGATE" ||
                        risk.treatment === "TRANSFER" ? (
                          <Chip
                            label={`RR: ${risk.residual_risk}`}
                            style={{
                              backgroundColor: getChipColor(risk.residual_risk),
                              minWidth: "90px",
                            }}
                          />
                        ) : (
                          <Chip
                            label={`IR: ${risk.inherent_risk}`}
                            style={{
                              backgroundColor: getChipColor(risk.inherent_risk),
                              minWidth: "90px", // Set your desired minimum width here
                            }}
                          />
                        )}
                      </>
                    ) : (
                      <Chip
                        label={`IR: ${risk.inherent_risk}`}
                        style={{
                          backgroundColor: getChipColor(risk.inherent_risk),
                          minWidth: "90px",
                        }}
                      />
                    )}
                    <Chip
                      label={`${risk.status}`}
                      style={{
                        minWidth: "50px",
                        marginLeft: "5px",
                      }}
                    />
                  </Box>
                </CardContent>
              </Card>
            ))
          ) : (
            <Typography sx={{ mt: 2 }}>No risks found.</Typography>
          )}
        </div>
      </div>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerClose}
        sx={{
          "& .MuiDrawer-paper": {
            width: "40%",
            padding: "20px",
          },
        }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isSubmitting}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">
            {selectedRisk ? "Edit Risk Register" : "Create Risk Register"}
          </Typography>
          {selectedRisk && !isEditing && (
            <Button
              variant="contained"
              onClick={() => setIsEditing(true)}
              style={{ marginBottom: "10px", backgroundColor: "#001b2b" }}
            >
              Edit
            </Button>
          )}
        </Box>
        <Grid container spacing={2}>
          {!selectedRisk && (
            <Grid item xs={12}>
              <TextField
                label="Risk Name"
                name="risk_name"
                value={formData.risk_name}
                onChange={handleInputChange}
                fullWidth
                type="text"
                margin="normal"
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              label="Confidentiality Impact"
              name="impact_c"
              value={formData.impact_c}
              onChange={handleInputChange}
              fullWidth
              disabled={!isEditing}
              type="number"
              margin="normal"
              InputProps={{
                endAdornment: (
                  <Tooltip title="Severity / Impact">
                    <IconButton onClick={handleOpenImpactModal}>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Integrity Impact"
              name="impact_i"
              value={formData.impact_i}
              onChange={handleInputChange}
              fullWidth
              disabled={!isEditing}
              type="number"
              margin="normal"
              InputProps={{
                endAdornment: (
                  <Tooltip title="Severity / Impact">
                    <IconButton onClick={handleOpenImpactModal}>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Availability Impact"
              name="impact_a"
              value={formData.impact_a}
              onChange={handleInputChange}
              fullWidth
              disabled={!isEditing}
              type="number"
              margin="normal"
              InputProps={{
                endAdornment: (
                  <Tooltip title="Severity / Impact">
                    <IconButton onClick={handleOpenImpactModal}>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Likelihood"
              name="likelihood"
              value={formData.likelihood}
              onChange={handleInputChange}
              fullWidth
              disabled={!isEditing}
              type="number"
              margin="normal"
              InputProps={{
                endAdornment: (
                  <Tooltip title="Probability / Likelihood">
                    <IconButton onClick={handleOpenLikelihoodModal}>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Inherent Risk Value"
              value={formData.inherent_risk_value}
              fullWidth
              disabled
              margin="normal"
              InputProps={{
                endAdornment: (
                  <Tooltip title="Inherent Risk Value Criteria">
                    <IconButton onClick={handleOpenCriteriaModal}>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Inherent Risk"
              value={formData.inherent_risk}
              fullWidth
              disabled
              margin="normal"
              InputProps={{
                endAdornment: (
                  <Tooltip title="Inherent Risk Matrix">
                    <IconButton onClick={handleOpenRiskMatrixModal}>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth disabled margin="normal">
              <InputLabel>Status</InputLabel>
              <Select
                name="status"
                value={formData.status || ""}
                onChange={handleInputChange}
                label="Status"
              >
                <MenuItem value="Open">Open</MenuItem>
                <MenuItem value="In Progress">In Progress</MenuItem>
                <MenuItem value="Closed">Closed</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth disabled={!isEditing} margin="normal">
              <InputLabel>Treatment</InputLabel>
              <Select
                name="treatment"
                value={formData.treatment || ""}
                onChange={handleInputChange}
                label="Treatment"
              >
                <MenuItem value="MITIGATE">Mitigate</MenuItem>
                <MenuItem value="TRANSFER">Transfer</MenuItem>
                <MenuItem value="AVOID">Avoid</MenuItem>
                <MenuItem value="ACCEPT">Accept</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Comment"
              name="justification"
              value={formData.justification || ""}
              onChange={handleInputChange}
              fullWidth
              disabled={!isEditing}
              margin="normal"
            />
          </Grid>
          {formData.treatment === "MITIGATE" && (
            <>
              <Grid item xs={12}>
                <Box>
                  <Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      mb={2}
                      sx={{
                        border: "1px solid lightgray",
                        padding: 2,
                        borderRadius: 1,
                      }}
                    >
                      <Box display="flex" alignItems="flex-end">
                        <TextField
                          label="Mitigation Plan"
                          name="mitigation_plan"
                          value={formData.applied_mitigation_plan || ""}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              applied_mitigation_plan: e.target.value,
                            })
                          }
                          fullWidth
                          margin="normal"
                          placeholder="Mitigation Plan"
                          disabled={!isEditing}
                          multiline
                          rows={4}
                          sx={{ marginBottom: 0 }}
                        />
                        <Button
                          variant="contained"
                          onClick={handleSelectSuggestion}
                          disabled={!isEditing}
                          sx={{
                            ml: 2,
                            backgroundColor: "#001b2b",
                            color: "#ffffff",
                            "&:hover": { backgroundColor: "#001b2b" },
                          }}
                        >
                          Suggestions
                        </Button>
                      </Box>

                      {showDropdown && (
                        <FormControl fullWidth sx={{ mt: 2 }}>
                          <InputLabel id="select-suggestion-label">
                            Select Suggestion
                          </InputLabel>
                          <Select
                            labelId="select-suggestion-label"
                            value={selectedOption}
                            onChange={handleOptionSelect}
                            fullWidth
                            MenuProps={{
                              PaperProps: {
                                sx: { maxHeight: 200, maxWidth: 500 },
                              },
                            }} // limit dropdown height
                          >
                            {dropdownOptions.map((option, index) => (
                              <MenuItem
                                key={index}
                                value={option}
                                sx={{
                                  whiteSpace: "normal",
                                  wordWrap: "break-word",
                                }}
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Target Date"
                  name="target_date"
                  type="date"
                  value={formData.target_date || ""}
                  onChange={handleInputChange}
                  fullWidth
                  disabled={!isEditing}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Artifact Name"
                  name="artifact_name"
                  value={formData.artifact_name || ""}
                  onChange={handleInputChange}
                  fullWidth
                  disabled={!isEditing}
                  margin="normal"
                  error={validationErrors.artifact_name}
                  helperText={
                    validationErrors.artifact_name
                      ? "Artifact Name is required"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Artifact Expiry Date"
                  name="artifact_expiry_date"
                  type="date"
                  value={formData.artifact_expiry_date || ""}
                  onChange={handleInputChange}
                  fullWidth
                  disabled={!isEditing}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  error={validationErrors.artifact_expiry_date}
                  helperText={
                    validationErrors.artifact_expiry_date
                      ? "Artifact Expiry Date is required"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  {formData.artifact_path && (
                    <Button
                      variant="contained"
                      onClick={() =>
                        handleOpenArtifactModal(formData.artifact_path)
                      }
                      fullWidth
                      style={{
                        marginTop: "16px",
                        backgroundColor: "#001b2b",
                      }}
                    >
                      View Artifact
                    </Button>
                  )}
                  <input
                    type="file"
                    onChange={handleFileChange}
                    disabled={!isEditing}
                    style={{ display: "none" }}
                    id="file-input"
                  />
                  <Box display="flex" justifyContent="flex-end" width="100%">
                    <label htmlFor="file-input" style={{ width: "100%" }}>
                      <Button
                        variant="contained"
                        component="span"
                        fullWidth
                        disabled={!isEditing}
                        style={{
                          marginTop: "16px",
                          backgroundColor: !isEditing ? "#eeeeee" : "#001b2b",
                          color: !isEditing ? "#808080" : "#fff",
                          width: "100%", // Ensure the button is 100% width
                        }}
                      >
                        Choose File
                      </Button>
                    </label>
                  </Box>
                  {validationErrors.artifact_path && (
                    <Typography variant="body2" color="error">
                      Artifact Path is required
                    </Typography>
                  )}
                  {file && (
                    <Typography
                      variant="body2"
                      style={{ marginTop: "10px", color: "black" }}
                    >
                      {file.name}
                    </Typography>
                  )}
                </div>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Modified Likelihood"
                  name="modified_likelihood"
                  value={formData.modified_likelihood}
                  onChange={handleInputChange}
                  fullWidth
                  disabled={!isEditing}
                  type="number"
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <Tooltip title="Probability / Likelihood">
                        <IconButton onClick={handleOpenLikelihoodModal}>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Residual Risk Value"
                  value={calculateResidualRiskValue()}
                  fullWidth
                  disabled
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <Tooltip title="Residual Risk Value Criteria">
                        <IconButton onClick={handleOpenCriteriaModal}>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Residual Risk"
                  value={determineRiskLevel(calculateResidualRiskValue())}
                  fullWidth
                  disabled
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <Tooltip title="Residual Risk Matrix">
                        <IconButton onClick={handleOpenRiskMatrixModal}>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
            </>
          )}
          {formData.treatment === "TRANSFER" && (
            <>
              <Grid item xs={12}>
                <TextField
                  label="Modified Likelihood"
                  name="modified_likelihood"
                  value={formData.modified_likelihood}
                  onChange={handleInputChange}
                  fullWidth
                  disabled={!isEditing}
                  type="number"
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <Tooltip title="Probability / Likelihood">
                        <IconButton onClick={handleOpenLikelihoodModal}>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Residual Risk Value"
                  value={calculateResidualRiskValue()}
                  fullWidth
                  disabled
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <Tooltip title="Residual Risk Value Criteria">
                        <IconButton onClick={handleOpenCriteriaModal}>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Residual Risk"
                  value={determineRiskLevel(calculateResidualRiskValue())}
                  fullWidth
                  disabled
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <Tooltip title="Residual Risk Matrix">
                        <IconButton onClick={handleOpenRiskMatrixModal}>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
            </>
          )}
          {formData.risk_assessment_type === "AUDITOR" && (
            <>
              <Grid item xs={12}>
                <TextField
                  label="Risk Observation"
                  name="risk_observation"
                  value={formData.risk_observation || ""}
                  onChange={handleInputChange}
                  fullWidth
                  disabled={!isEditing}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Risk Identification"
                  name="risk_identification"
                  value={formData.risk_identification || ""}
                  onChange={handleInputChange}
                  fullWidth
                  disabled={!isEditing}
                  margin="normal"
                />
              </Grid>
            </>
          )}
        </Grid>
        {isEditing && (
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button
              onClick={handleDrawerClose}
              variant="contained"
              style={{
                marginRight: "10px",
                background: "#eeeeee",
                color: "#808080",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={selectedRisk ? handleSaveClick : handleCreateSubmit}
              variant="contained"
              disabled={isSubmitting}
              style={{ backgroundColor: "#001b2b" }}
            >
              Submit
            </Button>
          </Box>
        )}
      </Drawer>

      <Modal
        open={openModal}
        onClose={handleCloseArtifactModal}
        aria-labelledby="artifact-modal-title"
        aria-describedby="artifact-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            ...style,
            width: isFullscreen ? "100%" : "80%",
            height: isFullscreen ? "100%" : "80%",
          }}
        >
          <IconButton
            onClick={handleCloseArtifactModal}
            sx={{ position: "absolute", top: 10, right: 10 }}
          >
            <CloseIcon />
          </IconButton>
          <IconButton
            onClick={handleToggleFullscreen}
            sx={{ position: "absolute", top: 10, right: 50 }}
          >
            {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </IconButton>
          <Typography id="artifact-modal-title" variant="h6" component="h2">
            Artifact Viewer
          </Typography>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              mt: 2,
              overflow: "auto",
              flexGrow: 1,
            }}
          >
            {renderFileViewer(artifactLink)}
          </Box>
        </Box>
      </Modal>

      <Modal
        open={riskMatrixModalOpen}
        onClose={handleCloseRiskMatrixModal}
        aria-labelledby="risk-matrix-modal-title"
        aria-describedby="risk-matrix-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={riskMatrixStyle}>
          <IconButton
            onClick={handleCloseRiskMatrixModal}
            sx={{ position: "absolute", top: 10, right: 10 }}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="risk-matrix-modal-title" variant="h6" component="h2">
            Risk Matrix
          </Typography>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              mt: 2,
              overflow: "auto",
              flexGrow: 1,
            }}
          >
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
                textAlign: "center",
              }}
            >
              <thead>
                <tr>
                  <th></th>
                  <th>Low</th>
                  <th>Medium</th>
                  <th>High</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>High</td>
                  <td style={{ backgroundColor: "yellow", height: "80px" }}>
                    Medium
                  </td>
                  <td style={{ backgroundColor: "red", height: "80px" }}>
                    High
                  </td>
                  <td style={{ backgroundColor: "red", height: "80px" }}>
                    High
                  </td>
                </tr>
                <tr>
                  <td>Medium</td>
                  <td style={{ backgroundColor: "green", height: "80px" }}>
                    Low
                  </td>
                  <td style={{ backgroundColor: "yellow", height: "80px" }}>
                    Medium
                  </td>
                  <td style={{ backgroundColor: "red", height: "80px" }}>
                    High
                  </td>
                </tr>
                <tr>
                  <td>Low</td>
                  <td style={{ backgroundColor: "green", height: "80px" }}>
                    Low
                  </td>
                  <td style={{ backgroundColor: "green", height: "80px" }}>
                    Low
                  </td>
                  <td style={{ backgroundColor: "yellow", height: "80px" }}>
                    Medium
                  </td>
                </tr>
              </tbody>
            </table>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={criteriaModalOpen}
        onClose={handleCloseCriteriaModal}
        aria-labelledby="criteria-modal-title"
        aria-describedby="criteria-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={criteriaStyle}>
          <IconButton
            onClick={handleCloseCriteriaModal}
            sx={{ position: "absolute", top: 10, right: 10 }}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="criteria-modal-title" variant="h6" component="h2">
            Risk Value Criteria
          </Typography>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              mt: 2,
              overflow: "auto",
              flexGrow: 1,
            }}
          >
            <Typography variant="body1" component="p">
              Risk Rating = Likelihood * (sum of Impact on CIA)
            </Typography>
            <ul>
              <li>
                <strong>Low:</strong> Less than or Equal to 9
              </li>
              <li>
                <strong>Medium:</strong> Greater than 9 and up to 18
              </li>
              <li>
                <strong>High:</strong> Greater than 18
              </li>
            </ul>
            <Typography variant="body1" component="p">
              Acceptable level of Risk is 1/3 of maximum Risk Rating = 9
            </Typography>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={impactModalOpen}
        onClose={handleCloseImpactModal}
        aria-labelledby="impact-modal-title"
        aria-describedby="impact-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={impactStyle}>
          <IconButton
            onClick={handleCloseImpactModal}
            sx={{ position: "absolute", top: 10, right: 10 }}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="impact-modal-title" variant="h6" component="h2">
            Severity / Impact
          </Typography>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              mt: 2,
              overflow: "auto",
              flexGrow: 1,
            }}
          >
            <Typography variant="body1" component="p">
              <strong>Severity / Impact:</strong>
            </Typography>
            <ol>
              <li>
                The impact may either be controlled, or would commonly result in
                less than moderate or acceptable damage to the business.
              </li>
              <li>
                The impact may commonly cause moderate damage to the business,
                requiring immediate corrective action.
              </li>
              <li>
                The impact may commonly cause severe damage to the business,
                requiring immediate cessation of the unsafe operation.
              </li>
            </ol>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={likelihoodModalOpen}
        onClose={handleCloseLikelihoodModal}
        aria-labelledby="likelihood-modal-title"
        aria-describedby="likelihood-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={likelihoodStyle}>
          <IconButton
            onClick={handleCloseLikelihoodModal}
            sx={{ position: "absolute", top: 10, right: 10 }}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="likelihood-modal-title" variant="h6" component="h2">
            Probability / Likelihood
          </Typography>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              mt: 2,
              overflow: "auto",
              flexGrow: 1,
            }}
          >
            <Typography variant="body1" component="p">
              <strong>Probability / Likelihood:</strong>
            </Typography>
            <ol>
              <li>
                Unlikely but possible to occur rarely during standard operations
              </li>
              <li>Likely to occur a few times during standard operations</li>
              <li>Likely to occur often during standard operations</li>
            </ol>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default RiskRegister;
