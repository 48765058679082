import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Typography,
  TextField,
  Card,
  CardContent,
  Divider,
  Drawer,
  Modal,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Autocomplete,
  Paper,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import cookie from "react-cookies";
import S3 from "react-aws-s3";
import useWindowDimensions from "../../useWindowDimensions";

const config = {
  bucketName: "whizzc-artifact",
  region: "us-east-1",
  accessKeyId: "AKIATUEBG6QGX4BZS2SE",
  secretAccessKey: "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",
  s3Url: "https://whizzc-artifact.s3.amazonaws.com",
};

function RequirementsListing() {
  const { complianceId } = useParams();
  const [requirements, setRequirements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRequirementId, setSelectedRequirementId] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedCheck, setSelectedCheck] = useState(null);
  const [subChecks, setSubChecks] = useState([]);
  const [uploadFormOpen, setUploadFormOpen] = useState(false);
  const [formValues, setFormValues] = useState({
    sub_check_name: "",
    artifact_path: "",
    comment: "",
    date_of_expiry: "",
  });
  const [uploading, setUploading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [artifactLink, setArtifactLink] = useState("");
  const [selectedSubCheck, setSelectedSubCheck] = useState(null);
  const [artifactFormVisible, setArtifactFormVisible] = useState(null); // New state to track which sub-check's form is open
  const fileInput = useRef(null);
  const { height, width } = useWindowDimensions();
  const [companyName, setCompanyName] = useState(""); // State to store company name
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedStandards, setSelectedStandards] = useState([]);

  useEffect(() => {
    fetchRequirements();
  }, [complianceId]);

  const fetchRequirements = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}${
          window.location.host.split(".")[0]
        }/${
          cookie.load("Language") || "en"
        }/artifact/requirements/${complianceId}`,
        { credentials: "include" }
      );
      const data = await response.json();
      setRequirements(data.requirement_list || []);
      setCompanyName(data.details.company_name); // Set the company name from API response

      console.log("Requirements data:", data);
    } catch (error) {
      console.error("Failed to fetch requirements", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSubChecks = async (check_id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}${
          window.location.host.split(".")[0]
        }/${cookie.load("Language") || "en"}/artifact/subchecks/${check_id}`,
        { credentials: "include" }
      );
      const data = await response.json();
      setSubChecks(data || []);
      console.log("Sub-checks data for check_id", check_id, ":", data);
    } catch (error) {
      console.error("Failed to fetch subchecks", error);
    }
  };

  const handleOpenDialog = (id) => {
    setSelectedRequirementId(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleDrawerOpen = (check) => {
    setSelectedCheck(check);
    fetchSubChecks(check.check_id);
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setSubChecks([]);
    setArtifactFormVisible(null); // Reset the artifact form visibility state
  };

  const handleUploadButtonClick = () => {
    setUploadFormOpen(true);
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleFileUpload = async (file) => {
    setUploading(true);
    const newFileName = file.name.replace(/[^a-zA-Z0-9.]/g, "_");
    const ReactS3Client = new S3(config);

    try {
      const data = await ReactS3Client.uploadFile(file, newFileName);
      if (data.status === 204) {
        setFormValues({
          ...formValues,
          artifact_path: data.location,
        });
      } else {
        console.error("File upload failed");
      }
    } catch (error) {
      console.error("File upload error", error);
    } finally {
      setUploading(false);
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const dateOfCapture = new Date().toISOString().split("T")[0];

    const postData = {
      ...formValues,
      is_document_added: formValues.artifact_path ? true : false,
      date_of_capture: dateOfCapture,
    };
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}${
          window.location.host.split(".")[0]
        }/${cookie.load("Language") || "en"}/artifact/addsubcheck/${
          selectedCheck.check_id
        }/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": cookie.load("csrftoken"),
          },
          credentials: "include",
          body: JSON.stringify(postData),
        }
      );
      if (response.ok) {
        fetchSubChecks(selectedCheck.check_id); // Refresh sub-checks list
        setUploadFormOpen(false);
        setFormValues({
          sub_check_name: "",
          artifact_path: "",
          comment: "",
          date_of_expiry: "",
        });
      } else {
        console.error("Failed to upload sub-check");
      }
    } catch (error) {
      console.error("Failed to upload sub-check", error);
    }
  };

  const handleOpenArtifactModal = (link) => {
    setArtifactLink(link);
    setOpenModal(true);
  };

  const handleCloseArtifactModal = () => {
    setOpenModal(false);
    setArtifactLink("");
    setIsFullscreen(false);
  };

  const handleToggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const handleSubCheckFormSubmit = async (event) => {
    event.preventDefault();

    const dateOfCapture = new Date().toISOString().split("T")[0];

    const postData = {
      artifact_path: formValues.artifact_path,
      comment: formValues.comment,
      date_of_capture: dateOfCapture,
      date_of_expiry: formValues.date_of_expiry,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}${
          window.location.host.split(".")[0]
        }/${cookie.load("Language") || "en"}/artifact/adddocument/${
          selectedSubCheck.sub_check_id
        }/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": cookie.load("csrftoken"),
          },
          credentials: "include",
          body: JSON.stringify(postData),
        }
      );
      if (response.ok) {
        fetchSubChecks(selectedCheck.check_id); // Refresh sub-checks list
        setSelectedSubCheck(null);
        setFormValues({
          sub_check_name: "",
          artifact_path: "",
          comment: "",
          date_of_expiry: "",
        });
        setArtifactFormVisible(null); // Reset the artifact form visibility state
      } else {
        console.error("Failed to upload document");
      }
    } catch (error) {
      console.error("Failed to upload document", error);
    }
  };

  const renderFileViewer = (link) => {
    const fileExtension = link.split(".").pop().toLowerCase();
    if (["pdf"].includes(fileExtension)) {
      return (
        <iframe
          src={link}
          title="Artifact"
          width="100%"
          height="100%"
          style={{ border: "none" }}
        />
      );
    }
    if (["doc", "docx", "xls", "xlsx", "ppt", "pptx"].includes(fileExtension)) {
      return (
        <iframe
          src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
            link
          )}`}
          title="Artifact"
          width="100%"
          height="100%"
          style={{ border: "none" }}
        />
      );
    }
    if (["png", "jpg", "jpeg", "gif"].includes(fileExtension)) {
      return (
        <img
          src={link}
          alt="Artifact"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
      );
    }
    return <Typography variant="h6">Unsupported file type</Typography>;
  };

  const minExpiryDate = new Date();
  minExpiryDate.setDate(minExpiryDate.getDate() + 1);

  const categoryNames = [
    ...new Set(requirements.map((item) => item.category_name).filter(Boolean)),
  ];

  const standardNames = [
    ...new Set(requirements.flatMap((item) => item.standards).filter(Boolean)),
  ];

  const handleCategoryChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedCategories(typeof value === "string" ? value.split(",") : value);
  };

  const handleStandardsChange = (event, newValue) => {
    setSelectedStandards(newValue);
  };

  const filteredRequirements = requirements.filter((requirement) => {
    const matchesCategory =
      selectedCategories.length > 0
        ? selectedCategories.includes(requirement.category_name)
        : true;

    const matchesSearch = requirement.requirement_name
      .toLowerCase()
      .includes(searchQuery.toLowerCase());

    const matchesStandard =
      selectedStandards.length > 0
        ? requirement.standards.some((standard) =>
            selectedStandards.includes(standard)
          )
        : true;

    return matchesCategory && matchesSearch && matchesStandard;
  });

  return (
    <div style={{ background: "#eeeeee", padding: "10px" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>



      <Box display="flex" mb={2}>
        <TextField
          label="Search Requirements"
          variant="outlined"
          value={searchQuery}
          onChange={handleSearch}
          style={{ width: "98%", marginLeft: "10px" }}
        />
      </Box>

      <Box display="flex" marginLeft={1}>
  {/* Category Filter */}
  <FormControl sx={{ width: "30%" }}>
    <InputLabel id="category-select-label">Category</InputLabel>
    <Select
      labelId="category-select-label"
      id="category-select"
      multiple
      value={selectedCategories}
      onChange={handleCategoryChange}
      renderValue={(selected) => selected.join(", ")}
    >
      {categoryNames.map((category) => (
        <MenuItem key={category} value={category}>
          <Checkbox checked={selectedCategories.indexOf(category) > -1} />
          <ListItemText primary={category} />
        </MenuItem>
      ))}
    </Select>
  </FormControl>

  {/* Standard Filter */}
  <FormControl sx={{ width: "55%", marginLeft: 1 }}>
  <Autocomplete
      multiple
      id="standard-select"
      options={standardNames}
      value={selectedStandards}
      onChange={handleStandardsChange}
      disableCloseOnSelect
      getOptionLabel={(option) => option}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox sx={{ marginRight: 1 }} checked={selectedStandards.indexOf(option) > -1} />
          {option}
        </li>
      )}
      renderInput={(params) => <TextField {...params} label="Standard Name" />}
      PaperComponent={(props) => <Paper {...props} style={{ maxHeight: 500 }} />}
    />
  </FormControl>
</Box>


      {filteredRequirements.length ? (
        filteredRequirements.map((requirement) => {
          const modifiedDescription =
            requirement.requirement_description.replace(
              "{Company Name}",
              companyName
            );
          return (
            <Accordion
              key={requirement.requirement_id}
              expanded={expanded === requirement.requirement_id}
              onChange={handleChange(requirement.requirement_id)}
              style={{ margin: "10px" }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box>
                  <Typography>{requirement.requirement_name}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    {modifiedDescription}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails
                style={{ background: "#f9f9f9", padding: "10px" }}
              >
                {requirement.checks && requirement.checks.length ? (
                  requirement.checks.map((check, index) => (
                    <Box key={check.check_id} style={{ margin: "10px 0" }}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box>
                          <Typography>{`Check ${index + 1}: ${
                            check.check_name
                          }`}</Typography>
                        </Box>
                        <Button
                          variant="contained"
                          onClick={() => handleDrawerOpen(check)}
                          style={{ background: "#eeeeee", color: "#808080" }}
                        >
                          Action
                        </Button>
                      </Box>
                      <Divider style={{ margin: "10px 0" }} />
                    </Box>
                  ))
                ) : (
                  <Typography>No checks found.</Typography>
                )}
              </AccordionDetails>
            </Accordion>
          );
        })
      ) : (
        <Typography sx={{ mt: 2 }}>No requirements found.</Typography>
      )}

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Edit Requirement</DialogTitle>
      </Dialog>

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerClose}
        sx={{
          "& .MuiDrawer-paper": {
            width: "40%",
            padding: "20px",
          },
        }}
      >
        {selectedCheck && (
          <Box>
            <Typography variant="h6">{selectedCheck.check_name}</Typography>
            <Typography variant="body1" gutterBottom>
              {selectedCheck.check_description}
            </Typography>
            <Button
              variant="contained"
              onClick={handleUploadButtonClick}
              style={{
                marginBottom: "10px",
                background: "#eeeeee",
                color: "#808080",
              }}
            >
              Upload
            </Button>
            {uploadFormOpen && (
              <Box component="form" onSubmit={handleFormSubmit} sx={{ mt: 2 }}>
                <TextField
                  name="sub_check_name"
                  label="Sub Check Name"
                  variant="outlined"
                  fullWidth
                  required
                  value={formValues.sub_check_name}
                  onChange={handleFormChange}
                  sx={{ mb: 2 }}
                />
                <TextField
                  type="file"
                  inputRef={fileInput}
                  fullWidth
                  required
                  onChange={(e) => handleFileUpload(e.target.files[0])}
                  sx={{ mb: 2 }}
                />
                <TextField
                  name="comment"
                  label="Comment"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={3}
                  value={formValues.comment}
                  onChange={handleFormChange}
                  sx={{ mb: 2 }}
                />
                <TextField
                  name="date_of_expiry"
                  label="Date of Expiry"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    min: minExpiryDate.toISOString().split("T")[0],
                  }}
                  fullWidth
                  required
                  value={formValues.date_of_expiry}
                  onChange={handleFormChange}
                  sx={{ mb: 2 }}
                />
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    onClick={() => setUploadFormOpen(false)}
                    variant="contained"
                    style={{
                      background: "#eeeeee",
                      color: "#808080",
                      marginRight: "10px",
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={uploading}
                    style={{ background: "#eeeeee", color: "#808080" }}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            )}
            <Typography variant="h6" style={{ marginTop: "20px" }}>
              Artifacts
            </Typography>
            {subChecks.length ? (
              subChecks.map((subCheck, index) => (
                <Box key={subCheck.sub_check_id} style={{ margin: "10px 0" }}>
                  <Typography variant="h6">{`Sub Check ${index + 1}: ${
                    subCheck.sub_check_name
                  }`}</Typography>
                  {subCheck.artifacts && subCheck.artifacts.length ? (
                    subCheck.artifacts.map((artifact, artIndex) => (
                      <Box
                        key={artIndex}
                        display="flex"
                        flexDirection="column"
                        style={{
                          background: "#f0f0f0",
                          padding: "10px",
                          borderRadius: "5px",
                          margin: "10px 0",
                        }}
                      >
                        <Typography variant="body2" color="textSecondary">
                          Comment: {artifact.comment || "No comment"}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          Date of Capture:{" "}
                          {new Date(
                            artifact.date_of_capture
                          ).toLocaleDateString() || "N/A"}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          Date of Expiry:{" "}
                          {new Date(
                            artifact.date_of_expiry
                          ).toLocaleDateString() || "N/A"}
                        </Typography>
                        <Button
                          variant="outlined"
                          onClick={() =>
                            handleOpenArtifactModal(artifact.artifact_path)
                          }
                          style={{
                            marginTop: "10px",
                            background: "#eeeeee",
                            color: "#808080",
                          }}
                        >
                          Open
                        </Button>
                      </Box>
                    ))
                  ) : (
                    <Typography>
                      No versions found for this artifact.
                    </Typography>
                  )}
                  <Button
                    variant="contained"
                    onClick={() => {
                      setSelectedSubCheck(subCheck);
                      setArtifactFormVisible(subCheck.sub_check_id); // Set the form visible state to the current sub-check id
                    }}
                    style={{
                      marginTop: "10px",
                      background: "#eeeeee",
                      color: "#808080",
                    }}
                  >
                    Renew
                  </Button>
                  {artifactFormVisible === subCheck.sub_check_id && (
                    <Box
                      component="form"
                      onSubmit={handleSubCheckFormSubmit}
                      sx={{ mt: 2 }}
                    >
                      <TextField
                        type="file"
                        inputRef={fileInput}
                        fullWidth
                        required
                        onChange={(e) => handleFileUpload(e.target.files[0])}
                        sx={{ mb: 2 }}
                      />
                      <TextField
                        name="comment"
                        label="Comment"
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={3}
                        value={formValues.comment}
                        onChange={handleFormChange}
                        sx={{ mb: 2 }}
                      />
                      <TextField
                        name="date_of_expiry"
                        label="Date of Expiry"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                          min: minExpiryDate.toISOString().split("T")[0],
                        }}
                        fullWidth
                        required
                        value={formValues.date_of_expiry}
                        onChange={handleFormChange}
                        sx={{ mb: 2 }}
                      />
                      <Box display="flex" justifyContent="flex-end">
                        <Button
                          onClick={() => setArtifactFormVisible(null)} // Close the form on cancel
                          variant="contained"
                          style={{
                            background: "#eeeeee",
                            color: "#808080",
                            marginRight: "10px",
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          type="submit"
                          disabled={uploading}
                          style={{ background: "#eeeeee", color: "#808080" }}
                        >
                          Submit
                        </Button>
                      </Box>
                    </Box>
                  )}
                  <Divider style={{ margin: "10px 0" }} />
                </Box>
              ))
            ) : (
              <Typography>No sub checks found.</Typography>
            )}
          </Box>
        )}
      </Drawer>

      <Modal
        open={openModal}
        onClose={handleCloseArtifactModal}
        aria-labelledby="artifact-modal-title"
        aria-describedby="artifact-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isFullscreen ? "100%" : width * 0.8,
            height: isFullscreen ? "100%" : height * 0.8,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            overflow: "hidden",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <IconButton
              onClick={handleCloseArtifactModal}
              style={{ background: "#eeeeee", color: "#808080" }}
            >
              <CloseIcon />
            </IconButton>
            <IconButton
              onClick={handleToggleFullscreen}
              style={{ background: "#eeeeee", color: "#808080" }}
            >
              {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
          </Box>
          <Box sx={{ height: "100%", width: "100%" }}>
            {renderFileViewer(artifactLink)}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default RequirementsListing;
