import React, { useState, useEffect, lazy, Suspense } from "react";
import cookie from "react-cookies";
import Loadingimg from "./img/loadinghumming.gif";
import RoutesConfig from "./Routes";
import { Helmet } from "react-helmet-async";
import Link from "@mui/material/Link";
import { styled } from "@mui/material/styles";
import { Button, Typography, Container, Box } from "@mui/material";
import { decryption, encryption } from "./auth/utilities";
import "./frameBuster.js";
// ----------------------------------------------------------------------
const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100%",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));
function App() {
  const subdomain = window.location.host.split(".")[0];
  const [role1, setRole] = useState(null);
  const [isTenant, setIsTenant] = useState(null);
  console.log("lpo", role1);

  useEffect(() => {
    const checkTenant = () => {
      fetch(
        process.env.REACT_APP_BACKEND +
          subdomain +
          "/" +
          (cookie.load("Language") || "en") +
          "/auth/checktenant/",
        {
          credentials: "include",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.tenant) {
            cookie.save("isTenant", true, { path: "/" });
            getSession();
          } else {
            cookie.save("isTenant", false, { path: "/" });
            setIsTenant(false);
          }
        })
        .catch((err) => {});
    };

    const getSession = () => {
      fetch(
        process.env.REACT_APP_BACKEND +
          subdomain +
          "/" +
          (cookie.load("Language") || "en") +
          "/auth/authenticate/",
        {
          credentials: "include",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setIsTenant(data.tenant);
          if (data.tenant) {
            console.log("isTenant", data);
            cookie.save("isTenant", true, { path: "/" });
            if (data.isAuthenticated) {
              console.log("data.isAuthenticated", data.isAuthenticated);
              cookie.save("isAuthenticated", true, { path: "/" });
              cookie.save("isTenantUser", data.isTenantUser, {
                path: "/",
              });
              // for local
              cookie.save("sessionid", data.sessionid.toString(), {
                path: "/",
              });
              cookie.save("csrftoken", data.csrftoken.toString(), {
                path: "/",
              });
              // end;
              cookie.save("user_role", encryption(data.user_role), {
                path: "/",
              });
              setRole(data.user_role);
            } else {
              cookie.save("user", null, { path: "/" });
              cookie.save("user_email", null, { path: "/" });
              cookie.save("user_role", encryption("01"), {
                path: "/",
              });
              cookie.save("isAuthenticated", false, { path: "/" });
              cookie.save("tenant", null, { path: "/" });
              setRole(null);
            }
          } else {
            cookie.save("isTenant", false, { path: "/" });
            setIsTenant(false);
          }
        })
        .catch((err) => {});
    };
    checkTenant();
  }, []);

  return (
    <div>
      {console.log("PPPP", isTenant)}
      {isTenant !== null ? (
        <div>
          {isTenant === true ? (
            <Suspense
              fallback={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    background: "#fefefe",
                  }}
                >
                  <img src={Loadingimg} alt="Loading" />
                </div>
              }
            >
              <RoutesConfig role1={decryption(cookie.load("user_role"))} />
            </Suspense>
          ) : (
            <Container>
              <StyledContent sx={{ textAlign: "center", alignItems: "center" }}>
                <Typography variant="h4" paragraph>
                  Sorry, tenant not found!
                </Typography>

                <Typography sx={{ color: "text.secondary" }}>
                  Sorry, we couldn’t find the tenant you’re looking for. Perhaps
                  you’ve mistyped the tenant name? Be sure to check your
                  spelling.
                </Typography>

                <Box
                  component="img"
                  src="https://minimal-kit-react.vercel.app/assets/illustrations/illustration_404.svg"
                  sx={{ height: 260, mx: "auto", my: { xs: 5, sm: 10 } }}
                />
                <Link
                  underline="hover"
                  color="inherit"
                  href="https://www.whizzc.com/"
                >
                  <Button
                    to="/d-error-404-page-not-foundd"
                    size="large"
                    variant="contained"
                  >
                    Go to whizzc
                  </Button>
                </Link>
              </StyledContent>
            </Container>
          )}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            background: "#fefefe",
          }}
        >
          <img src={Loadingimg} alt="Loading" />
        </div>
      )}
    </div>
  );
}

export default App;
