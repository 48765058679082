import "./App.css";
import { useState, useEffect, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import Loadingimg from "./img/loadinghumming.gif";
import Link from "@mui/material/Link";
import { styled } from "@mui/material/styles";
import { Button, Typography, Container, Box } from "@mui/material";
import EditDocument from "./pages/compliance/EditDocument";
import { decryption, encryption } from "./auth/utilities";
import cookie from "react-cookies";
import RequirementsListing from "./pages/Requirements/RequirementsListing";
import RiskRequirements from "./pages/RiskAssesment/RiskRequirements";
import RiskRegister from "./pages/RiskAssesment/RiskRegister";
import RiskAssesment from "./pages/RiskAssessment";
import VendorAssesment from "./pages/VendorAssessment";
import Artifactmainpage from "./pages/compliance/Artifacts/ArtifactMainPage";

// Lazy loaded components
const SideBar = lazy(() => import("./components/Sidebar/SideBar"));
const Layout = lazy(() => import("./Layout/Layout"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Analytics = lazy(() => import("./pages/People"));
const Myaudits = lazy(() => import("./pages/GapAssesmentMain"));
const Myaudits1 = lazy(() => import("./pages/GapAssesmentMain"));
const Myaudits2 = lazy(() => import("./pages/GapAssesmentMain"));
const Requirements = lazy(() => import("./pages/Requirements"));
const RiskAssessment = lazy(() => import("./pages/RiskAssessment"));
const VendorAssessment = lazy(() => import("./pages/VendorAssessment"));
const Integrations = lazy(() => import("./pages/Integration/IntegrationHome"));

const OurServices = lazy(() => import("./pages/Ourservices"));
const Profile = lazy(() => import("./pages/Profile/ProfilePage"));

const User_role = lazy(() =>
  import("./pages/Profile/Sidetab/pages/User_roles")
);
const Integration = lazy(() =>
  import("./pages/Profile/Sidetab/pages/integration")
);
const AuditHeader = lazy(() => import("./pages/AuditHeader"));
const Gapespage = lazy(() => import("./pages/compliance/Gapespage"));
const Setting = lazy(() => import("./pages/Profile/Sidetab/pages/Account"));
const ProcessMainPage = lazy(() =>
  import("./pages/ProcessPage/ProcessMainPage")
);
const Audit_AuditScope_tab = lazy(() =>
  import("./pages/compliance/Sidetab/pages/Account")
);
const QuestionPage = lazy(() => import("./pages/QuestionPage/ControlList"));
const PolicyDoc = lazy(() => import("./pages/compliance/PolicyDoc"));
const Document = lazy(() => import("./pages/compliance/Document"));
const Audit_Auditpolicydoc_tab = lazy(() =>
  import("./pages/compliance/Sidetab/pages/Audit_policydoc")
);
const AuditMainpage = lazy(() =>
  import("./pages/compliance/AuditE&I/AuditMainpage")
);
const CreateNewAudit = lazy(() =>
  import("./pages/compliance/AuditE&I/CreateNewAudit")
);
const Artifactlistings = lazy(() =>
  import("./pages/compliance/Artifacts/Artifactlistingpage")
);
const Audit_Auditartifact_tab = lazy(() =>
  import("./pages/compliance/Sidetab/pages/Artifact_audit")
);

const InternalAuditReport = lazy(() =>
  import("./pages/compliance/Sidetab/pages/AuditreportTab")
);
const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100%",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(5, 0),
}));
function UserAuth(role1) {
  const [inactive, setInactive] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [showButton, setShowButton] = useState(false);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 320;
  const animation = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 400 },
  });
  useEffect(() => {
    console.log("role1", role1);
    const timer = setTimeout(() => {
      setShowButton(true);
    }, 12000);

    return () => {
      clearTimeout(timer);
    };
  }, []);
  const handleButtonClick = () => {
    window.location.reload();
  };
  return (
    <div>
      {!isMobile ? (
        <>
          {["auditor", "admin", "approver", "auditee", "01", null].includes(
            decryption(cookie.load("user_role"))
          ) ? (
            <Router>
              <Layout>
                <SideBar
                  onCollapse={(inactive) => {
                    setInactive(inactive);
                  }}
                />
                <div
                  style={{}}
                  className={`container111 ${
                    inactive ? "noinactive" : "inactive"
                  }`}
                >
                  <Routes>
                    {decryption(cookie.load("user_role")) !== "" &&
                      ["auditor", "admin", "approver"].includes(
                        decryption(cookie.load("user_role"))
                      ) && (
                        <>
                          <Route path="/Audit" element={<AuditMainpage />} />
                          <Route
                            path="/Audit/Audit-Report"
                            element={<InternalAuditReport />}
                          />
                          <Route
                            path="/Audit/Audit-Scope"
                            element={<Audit_AuditScope_tab />}
                          />
                          <Route
                            path="/Audit/Policy-Documents"
                            element={<Audit_Auditpolicydoc_tab />}
                          />
                        </>
                      )}

                    {["auditor", "admin", "approver", "auditee"].includes(
                      decryption(cookie.load("user_role"))
                    ) && (
                      <>
                        <Route path="/" element={<Dashboard />} />
                        <Route path="/Profile" element={<Profile />} />
                        <Route
                          path="/integrations"
                          element={<Integrations />}
                        />
                        <Route
                          path="/Requirements"
                          element={<Requirements inactivedata={inactive} />}
                        />
                        <Route
                          path="/requirements/requirementsListing/:complianceId"
                          element={
                            <RequirementsListing inactivedata={inactive} />
                          }
                        />
                        <Route
                          path="/RiskAssessment"
                          element={<RiskAssessment inactivedata={inactive} />}
                        />
                        <Route
                          path="/RiskRequirements/:RiskAssessmentId"
                          element={<RiskRequirements inactivedata={inactive} />}
                        />
                        <Route
                          path="/RiskAssessment/RiskRegister/:riskAssessmentId"
                          element={<RiskRegister inactivedata={inactive} />}
                        />
                      </>
                    )}

                    {["auditee", "admin", "approver"].includes(
                      decryption(cookie.load("user_role"))
                    ) && (
                      <>
                        <Route path="/Setting" element={<Setting />} />

                        <Route
                          path="/Gaps"
                          element={<Myaudits inactivedata={inactive} />}
                        />
                        <Route
                          path="/Artifacts"
                          element={<Artifactmainpage />}
                        />
                        <Route
                          path="/Audit/Artifact"
                          element={<Audit_Auditartifact_tab />}
                        />
                        <Route path="/Integration" element={<Integration />} />
                      </>
                    )}

                    {["admin", "approver"].includes(
                      decryption(cookie.load("user_role"))
                    ) && (
                      <>
                        <Route
                          path="/Audit/Createaudit"
                          element={<CreateNewAudit />}
                        />
                        <Route
                          path="/Policy/Document/edit"
                          element={<EditDocument />}
                        />
                        <Route
                          path="/RiskAssesment"
                          element={<RiskAssesment />}
                        />

                        <Route
                          path="/GapAssesment"
                          element={<Myaudits inactivedata={inactive} />}
                        />
                        <Route
                          path="/VendorAssessment"
                          element={<VendorAssessment inactivedata={inactive} />}
                        />
                        <Route path="/People" element={<Analytics />} />
                        <Route
                          path="/Gapassesment/Newassesment/Compliances"
                          element={<OurServices />}
                        />
                        <Route
                          path="/Gapassesment/Newassesment/Compliances/Auditheader"
                          element={<AuditHeader />}
                        />
                        <Route
                          path="/Gapassesment/Newassesment/Compliances/Auditheader/processpage"
                          element={<ProcessMainPage />}
                        />
                        <Route
                          path="/Gapassesment/Newassesment/Compliances/Auditheader/processpage/questionpage"
                          element={<QuestionPage />}
                        />
                        <Route path="/Policy" element={<PolicyDoc />} />
                        <Route path="/Policy/Document" element={<Document />} />
                        <Route path="/User&roles" element={<User_role />} />
                      </>
                    )}

                    <Route
                      path="*"
                      element={
                        <Container>
                          <StyledContent
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                              color: "#001b2b",
                            }}
                          >
                            <Typography variant="h4" paragraph>
                              Sorry, page not found!
                            </Typography>

                            <Typography sx={{ color: "text.secondary" }}>
                              Sorry, we couldn’t find the page you’re looking
                              for. Perhaps you’ve mistyped the page name? Be
                              sure to check your spelling. You do not have
                            </Typography>

                            <Box
                              component="img"
                              src="https://minimal-kit-react.vercel.app/assets/illustrations/illustration_404.svg"
                              sx={{
                                height: 260,
                                mx: "auto",
                                my: { xs: 5, sm: 10 },
                              }}
                            />
                            <Link underline="hover" color="inherit" href="/">
                              <Button
                                to="/d-error-404-page-not-foundd"
                                size="large"
                                variant="contained"
                              >
                                Go to Dashboard
                              </Button>
                            </Link>
                          </StyledContent>
                        </Container>
                      }
                    />
                  </Routes>
                </div>
              </Layout>
            </Router>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                background: "#fefefe",
              }}
            >
              Unauthorized User ⚠️
            </div>
          )}
        </>
      ) : (
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            color: "black",
          }}
        >
          <h1 style={{ color: "#001b2b" }}>Expand your window</h1>
          Switch to a desktop with a viewing width of at least 320px to enjoy a
          better experience.
        </div>
      )}
    </div>
  );
}
export default UserAuth;
