import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Avatar, Box } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";
import cookie from "react-cookies";
import { LoadingButton } from "@mui/lab";

function EditDocument() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const subdomain = window.location.host.split(".")[0];
  const fileInputRef = useRef(null); // Create a ref for the file input
  const [editorData, setEditorData] = React.useState({ value: null });
  const [saveloading, setsaveloading] = React.useState(false);
  const [Editmode, setEditMode] = React.useState(false);

  // postapis
  const handleSubmit = () => {
    setsaveloading(true);
    const requestOption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": cookie.load("csrftoken"),
      },
      credentials: "include",
      body: JSON.stringify({
        document: editorData.value ? editorData.value : state.item.document,
        modified_by: cookie.load("user"),
      }),
    };
    const response = fetch(
      process.env.REACT_APP_BACKEND +
        subdomain +
        "/" +
        (cookie.load("Language") || "en") +
        "/policy/editpolicydocument/" +
        state.item.document_id +
        "/",
      requestOption
    ).then(
      (res) => {
        if (res.status === 200) {
          setsaveloading(false);
          // navigate("/Policy/Document", {
          //   state: state.document_id,
          // });
          setEditMode(false);
        } else {
          res.json().then((json) => {});
        }
      },
      (error) => {}
    );
  };
  //end  postapis
  // functions
  const handleChange = (value) => {
    setEditorData({ value });
  };
  const [expanded, setExpanded] = React.useState(false);

  const handleChange1 = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  // end functions
  return (
    <Box sx={{}}>
      <Accordion
        sx={{ marginTop: "-15px" }}
        expanded={expanded === "panel1"}
        onChange={handleChange1("panel1")}
      >
        <AccordionSummary
          // expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Box
            width={"100%"}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
          >
            <Box sx={{ flexShrink: 0 }}>
              <h3 style={{ marginLeft: "10px" }}>
                {" "}
                {{
                  ar: "إصدار",
                  sp: "Spanish",
                }[cookie.load("Language")] || "Version"}{" "}
                {state.item.version}
              </h3>
            </Box>
            <Box sx={{ color: "text.secondary", marginRight: "10px" }}>
              <div style={{ gap: "20px", display: "flex" }}>
                <Button
                  onClick={() => {
                    navigate("/Policy/Document", {
                      state: state.item.document_id,
                    });
                  }}
                  sx={{ color: "#808080", height: "30px" }}
                >
                  {" "}
                  {{
                    ar: "خلف",
                    sp: "Spanish",
                  }[cookie.load("Language")] || "Back"}
                </Button>
                {Editmode ? (
                  <LoadingButton
                    sx={{ height: "30px" }}
                    loading={saveloading}
                    onClick={handleSubmit}
                    color="success"
                    variant="outlined"
                  >
                    {" "}
                    {{
                      ar: "يحفظ",
                      sp: "Spanish",
                    }[cookie.load("Language")] || "Save"}
                  </LoadingButton>
                ) : (
                  <LoadingButton
                    sx={{ height: "30px" }}
                    loading={saveloading}
                    onClick={() => setEditMode(true)}
                    color="success"
                    variant="outlined"
                  >
                    {{
                      ar: "يحرر",
                      sp: "Spanish",
                    }[cookie.load("Language")] || "Edit"}
                  </LoadingButton>
                )}
              </div>{" "}
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box display={"flex"} flexDirection={"row"} gap={10}>
            {" "}
            <Typography fontWeight={"bold"}>Company Logo:</Typography>
            <Avatar alt="Remy Sharp" src={state.Reportdata.logo} />{" "}
          </Box>
          <br />
          <Box display={"flex"} flexDirection={"row"} gap={7}>
            {" "}
            <Typography fontWeight={"bold"}>Company Name:</Typography>
            <Typography>{state.Reportdata.company_name}</Typography>
          </Box>
          <br />
          <Box display={"flex"} flexDirection={"row"} gap={10}>
            {" "}
            <Typography fontWeight={"bold"}>Policy Name:</Typography>
            <Typography>{state.Reportdata.policy_name}</Typography>
          </Box>
          <br />
          <Box display={"flex"} flexDirection={"row"} gap={9.2}>
            {" "}
            <Typography fontWeight={"bold"}>Classification:</Typography>
            <Typography>Internal</Typography>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Box spellCheck={"false"} style={{ color: "black" }}>
        <SunEditor
          readOnly={!Editmode} // Set readOnly based on editable state
          width="a4"
          className="text-editor"
          setOptions={{
            height: "100%",
            buttonList: [
              [
                "font",
                "undo",
                "redo",
                "fontSize",
                "formatBlock",
                "bold",
                "underline",
                "italic",
                "strike",
                "subscript",
                "superscript",
                "removeFormat",
                "fontColor",
                "hiliteColor",
                "outdent",
                "indent",
                "align",
                "horizontalRule",
                "list",
                "lineHeight",
                "table", // Table insertion button
                "link",
                "image",
                "fullScreen",
                "print",
              ],
            ],
          }}
          setContents={
            editorData.value ? editorData.value : state.item.document
          }
          onChange={handleChange}
          placeholder="Write something ..."
        />
      </Box>
    </Box>
  );
}

export default EditDocument;
