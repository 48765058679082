import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useState, useEffect } from "react";
import InfoIcon from "@mui/icons-material/Info";
import cookie from "react-cookies";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  borderRadius: 3,
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 170,
    maxWidth: "100%",
    textOoverflow: "ellipsis",

    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function ArifactInfo({ text }) {
  const [loading, setLoading] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarmsg, setSnackbarmsg] = useState("");
  const [Profileinfo, setProfileinfo] = useState([]);
  const [openmodel, setOpenmodel] = React.useState(false);

  const handleOpenmodel = () => {
    setOpenmodel(true);
  };
  const handleClosemodel = () => {
    setOpenmodel(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setLoading(true);
    setAnchorEl(null);
  };
  const MINUTE_MS = 100;

  const snackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarmsg("");
  };

  return (
    <div>
      <div
        onClick={handleClick}
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "5px",
        }}
      >
        <div>
          <InfoIcon
            title="Click to know the Possible eveidance"
            style={{
              height: "25px",
              width: "25px",
              color: "#4285f4",
              marginLeft: "6px",
              marginTop: "6px",
            }}
          />
        </div>
      </div>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <div className="Name_mail_sec">
          <h6 style={{ marginLeft: "12px" }}>
            {
                  { ar: "الأدلة المحتملة", sp: "Spanish" }[
                    cookie.load("Language")
                  ] || "Possible Evidence"
                }
                </h6>
          {text.map((item, index) => {
            return (
              <div
                onClick={() => {
                  handleClose();
                }}
                className="textdd"
                style={{ textDecoration: "none" }}
                exact
              >
                <MenuItem style={{ gap: "10px" }} disableRipple>
                  {index + 1}.{item}
                </MenuItem>
              </div>
            );
          })}
        </div>
      </StyledMenu>
    </div>
  );
}
