import React, { useState, useEffect } from "react";
import { ReactSession } from "react-client-session";
import { styled } from "@mui/material/styles";
import {
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Button,
  Box,
  TextField,
  Typography,
  Drawer,
  Chip,
  InputLabel,
  FormControl,
  CircularProgress,
  Select,
  MenuItem,
  Card,
  CardContent,
  CardActions,
  Skeleton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import cookie from "react-cookies";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";

const StyledContent = styled("div")(({ theme }) => ({
  width: "100%",
  margin: "0 auto",
  minHeight: "100vh",
  padding: theme.spacing(5, 2),
  color: "black",
}));

const SearchBox = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(3),
  display: "flex",
  justifyContent: "left",
  width: "100%",
}));

const TopRightButton = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: theme.spacing(2),
}));

const CategoryChips = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: theme.spacing(1),
  marginBottom: theme.spacing(2),
}));

const FullWidthAccordion = styled(Accordion)(({ theme }) => ({
  width: "100%",
}));

const RiskAssessments = () => {
  ReactSession.set("PageTitle", "RiskAssessments");

  const navigate = useNavigate();
  const [complianceList, setComplianceList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredComplianceList, setFilteredComplianceList] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [newRiskAssessment, setNewRiskAssessment] = useState({
    category_ids: [],
    risk_assessment_name: "",
    risk_assessment_type: "",
  });

  const subdomain = window.location.host.split(".")[0];

  const fetchComplianceList = () => {
    fetch(
      `${process.env.REACT_APP_BACKEND}${subdomain}/${
        cookie.load("Language") || "en"
      }/riskassessment/riskassessment/`,
      {
        credentials: "include",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          console.log("Compliance List:", data);
          setComplianceList(data);
          setFilteredComplianceList(data);
          setLoading(false);
        } else {
          setComplianceList([]);
          setFilteredComplianceList([]);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error("Error fetching the compliance list:", err);
        setLoading(false);
      });
  };

  const fetchCategories = () => {
    fetch(
      `${process.env.REACT_APP_BACKEND}${subdomain}/${
        cookie.load("Language") || "en"
      }/riskassessment/categories/`,
      {
        credentials: "include",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          console.log("Categories:", data);
          setCategories(data);
        }
      })
      .catch((err) => {
        console.error("Error fetching categories:", err);
      });
  };

  useEffect(() => {
    fetchComplianceList();
    fetchCategories();
  }, []);

  useEffect(() => {
    setFilteredComplianceList(
      complianceList.filter((compliance) =>
        compliance.risk_assessment_name
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, complianceList]);

  const handleViewClick = (riskAssessment) => {
    navigate(`/RiskRequirements/${riskAssessment.risk_assessment_id}`, {
      state: riskAssessment,
    });
  };

  const handleCreateNewClick = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewRiskAssessment({
      ...newRiskAssessment,
      [name]: value,
    });
  };

  const handleCategoryClick = (categoryId) => {
    console.log("Category Id :", categoryId);
    setNewRiskAssessment((prevState) => {
      const category_ids = prevState.category_ids.includes(categoryId)
        ? prevState.category_ids.filter((id) => id !== categoryId)
        : [...prevState.category_ids, categoryId];
      return {
        ...prevState,
        category_ids,
      };
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const csrfToken = cookie.load("csrftoken"); // Ensure this is the correct name for your CSRF token cookie

    fetch(
      `${process.env.REACT_APP_BACKEND}${subdomain}/${
        cookie.load("Language") || "en"
      }/riskassessment/riskassessment/`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,
        },
        body: JSON.stringify(newRiskAssessment),
      }
    )
      .then((res) => {
        if (res.status === 403) {
          throw new Error("Forbidden");
        }
        return res.json();
      })
      .then((data) => {
        if (data) {
          console.log("New Risk Assessment Created:", data);
          fetchComplianceList(); // Refresh the compliance list
          setDrawerOpen(false); // Close the drawer
          setNewRiskAssessment({
            category_ids: [],
            risk_assessment_name: "",
            risk_assessment_type: "",
          });
        }
      })
      .catch((err) => {
        console.error("Error creating new risk assessment:", err);
      });
  };

  return (
    <Box>
      <StyledContent>
        <TopRightButton>
          <Typography variant="h5">Risk Assessments</Typography>
          <Button
            variant="contained"
            color="primary"
            endIcon={<AddIcon />}
            onClick={handleCreateNewClick}
            sx={{ background: "#001b2b" }}
          >
            Create New
          </Button>
        </TopRightButton>
        <SearchBox>
          <TextField
            style={{ maxWidth: "350px", width: "100%" }}
            label="Search Assessment"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </SearchBox>
        <br />
        {loading ? (
          <Box
            display="grid"
            gridTemplateColumns="repeat(4, 3fr)"
            gap={3}
            sx={{ width: "100%", marginTop: "-69px" }}
          >
            <Skeleton style={{ height: "320px" }} />
            <Skeleton style={{ height: "320px" }} animation="wave" />
            <Skeleton style={{ height: "320px" }} animation={false} />

            <Skeleton style={{ height: "320px" }} animation="wave" />
            <Skeleton style={{ height: "320px", marginTop: "-120px" }} />
            <Skeleton
              style={{ height: "320px", marginTop: "-120px" }}
              animation="wave"
            />
            <Skeleton
              style={{ height: "320px", marginTop: "-120px" }}
              animation={false}
            />

            <Skeleton
              style={{ height: "320px", marginTop: "-120px" }}
              animation="wave"
            />
          </Box>
        ) : (
          <Grid container spacing={3}>
            <Box
              display="grid"
              gridTemplateColumns="repeat(4, 3fr)"
              gap={2}
              className="ourservicescard"
              sx={{ marginLeft: "20px" }}
            >
              {filteredComplianceList.map((compliance) => (
                <Card>
                  <CardContent>
                    {/* <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  Word of the Day
                </Typography> */}
                    <Typography variant="h5" component="div">
                      Name :{compliance.risk_assessment_name}
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                      Assessment Type : {compliance.risk_assessment_type}
                    </Typography>
                    <Typography variant="body2">
                      Created On:{" "}
                      {new Date(compliance.created_on).toLocaleDateString()}{" "}
                      <br />
                      {/* {`"${example}"`} */}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      onClick={() => handleViewClick(compliance)}
                      size="small"
                    >
                      View
                    </Button>
                  </CardActions>
                </Card>
                // <Grid item xs={12} key={compliance.risk_assessment_id}>
                //   <FullWidthAccordion>
                //     <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                //       <Typography>{compliance.risk_assessment_name}</Typography>
                //     </AccordionSummary>
                //     <AccordionDetails>
                //       <Typography
                //         variant="body2"
                //         color="text.secondary"
                //         style={{ color: "black" }}
                //       >
                //         Type: {compliance.risk_assessment_type}
                //       </Typography>
                //       <Typography
                //         variant="body2"
                //         color="text.secondary"
                //         style={{ color: "black" }}
                //       >
                //         Created On:{" "}
                //         {new Date(compliance.created_on).toLocaleDateString()}
                //       </Typography>
                //       <Box
                //         sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}
                //       >
                //         <Button
                //           variant="contained"
                //           color="primary"
                //           onClick={() => handleViewClick(compliance)}
                //         >
                //           View
                //         </Button>
                //       </Box>
                //     </AccordionDetails>
                //   </FullWidthAccordion>
                // </Grid>
              ))}
            </Box>
          </Grid>
        )}
      </StyledContent>
      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
        <Box sx={{ width: 350, p: 3 }}>
          <Typography variant="h6" component="div" gutterBottom>
            Create New Risk Assessment
          </Typography>
          <form onSubmit={handleFormSubmit}>
            <CategoryChips>
              {categories.map((category) => (
                <Chip
                  key={category.category_id}
                  label={category.category_name}
                  clickable
                  color={
                    newRiskAssessment.category_ids.includes(
                      category.category_id
                    )
                      ? "primary"
                      : "default"
                  }
                  onClick={() => handleCategoryClick(category.category_id)}
                />
              ))}
            </CategoryChips>
            <TextField
              fullWidth
              margin="normal"
              label="Name of Risk Assessment"
              variant="outlined"
              name="risk_assessment_name"
              value={newRiskAssessment.risk_assessment_name}
              onChange={handleInputChange}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel id="risk-type-label">Type</InputLabel>
              <Select
                labelId="risk-type-label"
                name="risk_assessment_type"
                value={newRiskAssessment.risk_assessment_type}
                onChange={handleInputChange}
              >
                <MenuItem value="SELF">SELF</MenuItem>
                <MenuItem value="AUDITOR">AUDITOR</MenuItem>
              </Select>
            </FormControl>
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
              <Button variant="contained" color="primary" type="submit">
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Drawer>
    </Box>
  );
};

export default RiskAssessments;
