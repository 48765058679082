import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import { FaUserCog, FaEdit, FaSignOutAlt, FaCaretDown } from "react-icons/fa";
import { NavLink, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import cookie from "react-cookies";
import { Snackbar } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import LetteredAvatar from "lettered-avatar";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import { fontWeight } from "@mui/system";
import OutlinedInput from "@mui/material/OutlinedInput";
import passwordSuc from "../../img/sucesspassword.gif";
import FormHelperText from "@mui/material/FormHelperText";
import CircularProgress from "@mui/material/CircularProgress";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  borderRadius: 3,

  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 170,
    maxWidth: 290,
    textOoverflow: "ellipsis",

    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function ArtifactAssignUser({
  text,
  Users,
  parentFunction,
  Loading,
}) {
  const [loading, setLoading] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarmsg, setSnackbarmsg] = useState("");
  const [Profileinfo, setProfileinfo] = useState([]);
  const [openmodel, setOpenmodel] = React.useState(false);
  const [PasswordUpdated, setPasswordUpdated] = useState(true);
  const [old_wrong, setold_wrong] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const handleOpenmodel = () => {
    setOpenmodel(true);
  };
  const handleClosemodel = () => {
    setOpenmodel(false);
  };
  useEffect(async () => {
    setProfileinfo(Users);
  }, []);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setLoading(true);
    setAnchorEl(null);
  };
  const MINUTE_MS = 100;

  useEffect(() => {
    const interval = setInterval(() => {
      if (Loading === false) {
        setLoading(false);
      } else {
        setLoading(true);
      }
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);

  const snackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarmsg("");
  };

  return (
    <div>
      <div
        onClick={handleClick}
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "5px",
        }}
      >
        {loading ? (
          <div>
            <CircularProgress
              style={{
                marginTop: "5px",
                height: "20px",
                width: "20px",
                overflow: "hidden",
              }}
            />
          </div>
        ) : (
          <div>
            {text.profile_name ? (
              <div>
                {Profileinfo.profile_photo ? (
                  <div className="">
                    <img className="Profile-bg-container" src={passwordSuc} />
                  </div>
                ) : (
                  <div>
                    <LetteredAvatar
                      name={text.profile_name ? text.profile_name : "?"}
                      options={{
                        size: 32,
                        twoLetter: true,
                        shape: "round",
                      }}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div>
                <AccountCircleIcon style={{ height: "33px", width: "33px" }} />
              </div>
            )}
          </div>
        )}
        {/*<FaCaretDown
          style={{ marginTop: "6px", marginRight: "10px", opacity: 0.7 }}
            />*/}
      </div>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <div className="Name_mail_sec">
          {Profileinfo.map((item, index) => {
            return (
              <div
                onClick={() => {
                  handleClose();
                  parentFunction(
                    item.user_profile_id,
                    text.audit_header_id,
                    text.artifact_id
                  );
                }}
                className="textdd"
                style={{ textDecoration: "none" }}
                exact
              >
                <MenuItem style={{ gap: "10px" }} disableRipple>
                  {Profileinfo.profile_photo ? (
                    <div className="">
                      <img className="Profile-bg-container" src={passwordSuc} />
                    </div>
                  ) : (
                    <div>
                      <LetteredAvatar
                        name={item.profile_name}
                        options={{
                          size: 32,
                          twoLetter: true,
                          shape: "round",
                        }}
                      />
                    </div>
                  )}
                  {item.profile_name}
                </MenuItem>
              </div>
            );
          })}
        </div>
      </StyledMenu>
    </div>
  );
}
