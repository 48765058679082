import React, { useState, useEffect, useRef } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Drawer,
  Typography,
  TextField,
  Card,
  CardContent,
  Modal,
  Tabs,
  Tab,
  IconButton,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Checkbox,
  ListItemText,
  Chip,
  Alert,
  FormControlLabel,
  Tooltip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import CloseIcon from "@mui/icons-material/Close";
import cookie from "react-cookies";
import S3 from "react-aws-s3";
import useWindowDimensions from "../../useWindowDimensions";
import LoadingButton from "@mui/lab/LoadingButton";
import PropTypes from "prop-types";
import { decryption } from "../../auth/utilities";
import RiskRegister from "./RiskRegister";

const config = {
  bucketName: "whizzc-artifact",
  region: "us-east-1",
  accessKeyId: "AKIATUEBG6QGX4BZS2SE",
  secretAccessKey: "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",
  s3Url: "https://whizzc-artifact.s3.amazonaws.com",
};

const style = {
  position: "absolute",
  color: "#001b2b",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 3,
  p: 4,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function RiskRequirements(inactivedata) {
  const { RiskAssessmentId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const Inactivedata = inactivedata.inactivedata;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const subdomain = window.location.host.split(".")[0];
  const [requirements, setRequirements] = useState([]);
  const [riskAssessmentDetail, setRiskAssessmentDetail] = useState(null);
  const [companyName, setCompanyName] = useState(""); // State to store company name
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRequirementId, setSelectedRequirementId] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedCheck, setSelectedCheck] = useState(null);
  const [subChecks, setSubChecks] = useState([]);
  const [uploadFormOpen, setUploadFormOpen] = useState(false);
  const [artifactFormVisible, setArtifactFormVisible] = useState(null);

  const [acknowledgmentChecked, setAcknowledgmentChecked] = useState(false);

  const [formValues, setFormValues] = useState({
    sub_check_name: "",
    artifact_path: "",
    comment: "",
    date_of_expiry: "",
  });
  const [uploading, setUploading] = useState(false);
  const [fileWarning, setFileWarning] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [artifactLink, setArtifactLink] = useState("");
  const fileInput = useRef(null);
  const { height, width } = useWindowDimensions();
  const [selectedSubCheck, setSelectedSubCheck] = useState(null);

  const [value, setValue] = useState(0);
  const [statusFilter, setStatusFilter] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState([]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const handleCategoryFilterChange = (event) => {
    setCategoryFilter(event.target.value);
  };
  const handleCancel = () => {
    setFormValues({
      sub_check_name: "",
      artifact_path: "",
      comment: "",
      date_of_expiry: "",
    });
    setUploadFormOpen(false);
    setAcknowledgmentChecked(false);
  };

  const containerRef = useRef(null);

  useEffect(() => {
    fetchRiskAssessment();
  }, [RiskAssessmentId]);

  const fetchRiskAssessment = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}${
          window.location.host.split(".")[0]
        }/${
          cookie.load("Language") || "en"
        }/riskassessment/riskassessment/${RiskAssessmentId}`,
        { credentials: "include" }
      );
      const data = await response.json();
      setRiskAssessmentDetail(data || []);
      setCompanyName(data.company_name); // Set the company name from API response
      fetchRequirements(data.company_name); // Fetch requirements after getting company name
    } catch (error) {
      console.error("Failed to fetch requirements", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchRequirements = async (companyName) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}${
          window.location.host.split(".")[0]
        }/${
          cookie.load("Language") || "en"
        }/riskassessment/riskrequirementlist/${RiskAssessmentId}`,
        { credentials: "include" }
      );
      const data = await response.json();
      // Replace {Company Name} with the actual company name
      const updatedRequirements = data.map((requirement) => ({
        ...requirement,
        requirement_description: requirement.requirement_description.replace(
          "{Company Name}",
          companyName
        ),
      }));
      setRequirements(updatedRequirements);
    } catch (error) {
      console.error("Failed to fetch requirements", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSubChecks = async (check_id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}${
          window.location.host.split(".")[0]
        }/${cookie.load("Language") || "en"}/artifact/subchecks/${check_id}`,
        { credentials: "include" }
      );
      const data = await response.json();
      setSubChecks(data || []);
    } catch (error) {
      console.error("Failed to fetch subchecks", error);
    }
  };

  const handleOpenDialog = (id) => {
    setSelectedRequirementId(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleExpand = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleDrawerOpen = (check) => {
    setSelectedCheck(check);
    fetchSubChecks(check.check_id);
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setSubChecks([]);
    setUploadFormOpen(false);
    setSelectedSubCheck(null);
    setArtifactFormVisible(null); // Reset artifact form visibility state
  };

  const handleUploadButtonClick = () => {
    setUploadFormOpen(true);
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleFileUpload = async (file) => {
    setUploading(true);
    const newFileName = file.name.replace(/[^a-zA-Z0-9.]/g, "_");
    const ReactS3Client = new S3(config);

    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

    if (file.lastModified < oneYearAgo.getTime()) {
      setFileWarning("The artifact should be newer than one year.");
      setUploading(false);
      return;
    } else {
      setFileWarning("");
    }

    try {
      const data = await ReactS3Client.uploadFile(file, newFileName);
      if (data.status === 204) {
        setFormValues({
          ...formValues,
          artifact_path: data.location,
        });
      } else {
        console.error("File upload failed");
      }
    } catch (error) {
      console.error("File upload error", error);
    } finally {
      setUploading(false);
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    // Format date_of_capture to 'YYYY-MM-DD'
    const dateOfCapture = new Date().toISOString().split("T")[0];

    const postData = {
      ...formValues,
      is_document_added: formValues.artifact_path ? true : false,
      date_of_capture: dateOfCapture,
    };
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}${
          window.location.host.split(".")[0]
        }/${cookie.load("Language") || "en"}/artifact/addsubcheck/${
          selectedCheck.check_id
        }/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": cookie.load("csrftoken"),
          },
          credentials: "include",
          body: JSON.stringify(postData),
        }
      );
      if (response.ok) {
        fetchSubChecks(selectedCheck.check_id); // Refresh sub-checks list
        setUploadFormOpen(false);
        setFormValues({
          sub_check_name: "",
          artifact_path: "",
          comment: "",
          date_of_expiry: "",
        });
      } else {
        console.error("Failed to upload sub-check");
      }
    } catch (error) {
      console.error("Failed to upload sub-check", error);
    }
  };

  const handleAddDocument = async (event) => {
    event.preventDefault();

    // Format date_of_capture to 'YYYY-MM-DD'
    const dateOfCapture = new Date().toISOString().split("T")[0];

    const postData = {
      artifact_path: formValues.artifact_path,
      comment: formValues.comment,
      date_of_capture: dateOfCapture,
      date_of_expiry: formValues.date_of_expiry,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}${
          window.location.host.split(".")[0]
        }/${cookie.load("Language") || "en"}/artifact/adddocument/${
          selectedSubCheck.sub_check_id
        }/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": cookie.load("csrftoken"),
          },
          credentials: "include",
          body: JSON.stringify(postData),
        }
      );
      if (response.ok) {
        fetchSubChecks(selectedCheck.check_id); // Refresh sub-checks list
        setSelectedSubCheck(null);
        setArtifactFormVisible(null); // Close the form after successful submission
        setFormValues({
          sub_check_name: "",
          artifact_path: "",
          comment: "",
          date_of_expiry: "",
        });
      } else {
        console.error("Failed to upload document");
      }
    } catch (error) {
      console.error("Failed to upload document", error);
    }
  };

  const handleOpenArtifactModal = (link) => {
    setArtifactLink(link);
    setOpenModal(true);
  };

  const handleCloseArtifactModal = () => {
    setOpenModal(false);
    setArtifactLink("");
    setIsFullscreen(false);
  };

  const handleToggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const handleSubmit = (event) => {
    setButtonLoading(true);
    event.preventDefault();

    const requestOption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": cookie.load("csrftoken"),
      },
      credentials: "include",
      body: JSON.stringify({
        created_by: cookie.load("user"),
      }),
    };
    fetch(
      `${process.env.REACT_APP_BACKEND}${subdomain}/${
        cookie.load("Language") || "en"
      }/riskassessment/riskregister/${
        riskAssessmentDetail.risk_assessment_id
      }/`,
      requestOption
    ).then(
      (res) => {
        if (res.status === 201) {
          fetchRiskAssessment();
          setOpen(false);
          setButtonLoading(false);
        } else {
          console.error("Failed to create risk register");
        }
      },
      (error) => {
        console.log("error", error);
      }
    );
  };

  const renderFileViewer = (link) => {
    const fileExtension = link.split(".").pop().toLowerCase();
    if (["pdf"].includes(fileExtension)) {
      return (
        <iframe
          src={link}
          title="Artifact"
          width="100%"
          height="100%"
          style={{ border: "none", flexGrow: 1 }}
        />
      );
    }
    if (["doc", "docx", "xls", "xlsx", "ppt", "pptx"].includes(fileExtension)) {
      return (
        <iframe
          src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
            link
          )}`}
          title="Artifact"
          width="100%"
          height="100%"
          style={{ border: "none", flexGrow: 1 }}
        />
      );
    }
    if (["png", "jpg", "jpeg", "gif"].includes(fileExtension)) {
      return (
        <img
          src={link}
          alt="Artifact"
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            objectFit: "contain",
            flexGrow: 1,
          }}
        />
      );
    }
    return <Typography variant="h6">Unsupported file type</Typography>;
  };

  const filteredRequirements = requirements
    .filter((requirement) =>
      requirement.requirement_name
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    )
    .filter(
      (requirement) =>
        (statusFilter.length === 0 ||
          statusFilter.includes(requirement.status)) &&
        (categoryFilter.length === 0 ||
          categoryFilter.includes(requirement.category_name))
    );

  const minExpiryDate = new Date();
  minExpiryDate.setDate(minExpiryDate.getDate() + 1); // Set to tomorrow to ensure it's not today or earlier

  const uniqueCategories = [
    ...new Set(requirements.map((req) => req.category_name)),
  ];

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {cookie.load("Language") === "ar" ? "ملحوظة⚠️" : "Note ⚠️"}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {cookie.load("Language") === "ar"
              ? "بمجرد تقديمه، لا يمكن تعديل تقييمك. يرجى التأكد من الإجابة على كل سؤال. سيتم نقل البيانات المفقودة إلى الفجوة. إذا كان موافق انقر فوق إرسال"
              : "Once the Risk Register is created, all the checks that failed will be documented as risks. Please ensure that all information is up-to-date before proceeding. "}
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "30px",
              float: "right",
              gap: "20px",
            }}
          >
            <Button
              variant="contained"
              style={{ background: "#eeeeee", color: "#808080" }}
              onClick={handleClose}
            >
              {cookie.load("Language") === "ar" ? "يلغي" : "Cancel"}
            </Button>
            <LoadingButton
              style={{
                background: !buttonLoading ? "#00324e" : "#808080",
                color: "#ffff",
              }}
              onClick={handleSubmit}
              loading={buttonLoading}
              variant="contained"
            >
              {cookie.load("Language") === "ar" ? "يُقدِّم" : "Create"}
            </LoadingButton>
          </div>
        </Box>
      </Modal>

      <Box
        sx={{
          marginTop: "-15px",
          height: "100%",
          width: Inactivedata ? width - 250 : width - 55,
          zIndex: 10,
          overflow: "hidden",
          position: "fixed",
        }}
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            width: "100%",
            background: "#ffffff",
            zIndex: 10,
          }}
        >
          <Tabs
            value={value}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            {!["auditee"].includes(decryption(cookie.load("user_role"))) && (
              <Tab
                value={0}
                style={{
                  fontFamily: "'DM Serif Text', serif",
                  fontWeight: "bold",
                }}
                label="Requirements"
                {...a11yProps(0)}
              />
            )}
            {riskAssessmentDetail?.is_submitted ? (
              <Tab
                value={1}
                style={{
                  fontFamily: "'DM Serif Text', serif",
                  fontWeight: "bold",
                }}
                label="Risk Register"
                {...a11yProps(1)}
              />
            ) : (
              <Tooltip
                title="Risk Register is not Created yet!"
                disableInteractive
              >
                <span>
                  <Tab
                    value={1}
                    style={{
                      fontFamily: "'DM Serif Text', serif",
                      fontWeight: "bold",
                      pointerEvents: riskAssessmentDetail?.is_submitted
                        ? "auto"
                        : "none",
                      color: "#808080",
                      opacity: riskAssessmentDetail?.is_submitted ? 1 : 0.5,
                    }}
                    label="Risk Register"
                    {...a11yProps(1)}
                  />
                </span>
              </Tooltip>
            )}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <div
            style={{
              width: "100%",
              height: "80vh",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ flexShrink: 0 }}>
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                gap={2}
                color={"#000"}
              >
                <Box
                  marginLeft={"20px"}
                  gap={2}
                  display={"flex"}
                  flexDirection={"row"}
                >
                  <Typography variant="h5">Requirements</Typography>
                  <Chip
                    style={{ marginRight: "20px" }}
                    label={`Total Count: ${filteredRequirements.length}`}
                  />
                </Box>
                <Button
                  onClick={
                    riskAssessmentDetail
                      ? !riskAssessmentDetail.is_submitted
                        ? handleOpen
                        : null
                      : null
                  }
                  disabled={riskAssessmentDetail?.is_submitted}
                  variant="contained"
                  sx={{
                    color: "#ffffff",
                    background: "#001b2b",
                    marginBottom: "16px",
                    marginRight: "16px",
                    zIndex: 11,
                  }}
                  color="primary"
                  aria-label="add"
                >
                  {riskAssessmentDetail?.is_submitted
                    ? "Risk Register Created"
                    : "Create Risk Register"}
                </Button>
              </Box>
              <Box
                gap={5}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  p: 1,
                  m: 1,
                  borderRadius: 1,
                }}
              >
                <TextField
                  fullWidth
                  label="Search Requirements"
                  variant="outlined"
                  value={searchQuery}
                  onChange={handleSearch}
                />
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Status</InputLabel>
                  <Select
                    multiple
                    value={statusFilter}
                    onChange={handleStatusFilterChange}
                    renderValue={(selected) => selected.join(", ")}
                    label="Status"
                  >
                    <MenuItem value="pass">
                      <Checkbox checked={statusFilter.indexOf("pass") > -1} />
                      <ListItemText primary="Pass" />
                    </MenuItem>
                    <MenuItem value="fail">
                      <Checkbox checked={statusFilter.indexOf("fail") > -1} />
                      <ListItemText primary="Failed" />
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Category</InputLabel>
                  <Select
                    multiple
                    value={categoryFilter}
                    onChange={handleCategoryFilterChange}
                    renderValue={(selected) => selected.join(", ")}
                    label="Category"
                  >
                    {uniqueCategories.map((category, index) => (
                      <MenuItem key={index} value={category}>
                        <Checkbox
                          checked={categoryFilter.indexOf(category) > -1}
                        />
                        <ListItemText primary={category} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>

            <div style={{ flexGrow: 1, minHeight: "0", overflowY: "auto" }}>
              <div style={{ minHeight: "100%", overflowY: "auto" }}>
                {filteredRequirements.length ? (
                  filteredRequirements.map((requirement, index) => {
                    const modifiedDescription =
                      requirement.requirement_description.replace(
                        "{Company Name}",
                        companyName
                      );
                    return (
                      <Accordion
                        key={requirement.requirement_id}
                        expanded={expanded === requirement.requirement_id}
                        onChange={handleExpand(requirement.requirement_id)}
                        style={{ margin: "10px" }}
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            width="100%"
                          >
                            <Box display="flex" alignItems="center">
                              <Box display="block">
                                <Typography variant="body1" component="div">
                                  <strong>{`R${index + 1}. ${
                                    requirement.requirement_name
                                  }`}</strong>
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  component="div"
                                >
                                  {modifiedDescription}
                                </Typography>
                              </Box>
                              <Chip
                                label={requirement.category_name}
                                style={{ marginLeft: "20px" }}
                              />
                            </Box>
                            {requirement.status === "pass" ? (
                              <Box
                                style={{
                                  backgroundColor: "rgba(0, 128, 0, 0.5)", // Green with transparency
                                  color: "white",
                                  borderRadius: "5px",
                                  padding: "5px",
                                  marginLeft: "15px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "60px", // Equal width for both status
                                }}
                              >
                                <Typography style={{ fontSize: "14px" }}>
                                  Pass
                                </Typography>
                              </Box>
                            ) : requirement.status === "fail" ? (
                              <Box
                                style={{
                                  backgroundColor: "rgba(255, 0, 0, 0.5)", // Red with transparency
                                  color: "white",
                                  borderRadius: "5px",
                                  padding: "5px",
                                  marginLeft: "15px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "60px", // Equal width for both status
                                }}
                              >
                                <Typography style={{ fontSize: "14px" }}>
                                  Failed
                                </Typography>
                              </Box>
                            ) : null}
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails
                          style={{ background: "#f9f9f9", padding: "10px" }}
                        >
                          {requirement.checks && requirement.checks.length ? (
                            requirement.checks.map((check, checkIndex) => (
                              <Box
                                key={check.check_id}
                                style={{ margin: "10px 0" }}
                              >
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Box>
                                    <Typography>{`Check ${checkIndex + 1}: ${
                                      check.check_name
                                    }`}</Typography>
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                    >
                                      {check.check_description}
                                    </Typography>
                                  </Box>
                                  <Button
                                    variant="contained"
                                    onClick={() => handleDrawerOpen(check)}
                                    sx={{
                                      backgroundColor: "#001b2b",
                                      color: "white",
                                      mr: 2,
                                    }}
                                  >
                                    Action
                                  </Button>
                                </Box>
                                <Divider style={{ margin: "10px 0" }} />
                              </Box>
                            ))
                          ) : (
                            <Typography>No checks found.</Typography>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    );
                  })
                ) : (
                  <Typography sx={{ mt: 2 }}>No requirements found.</Typography>
                )}
              </div>
            </div>
            <Drawer
              anchor="right"
              open={drawerOpen}
              onClose={handleDrawerClose}
              sx={{
                "& .MuiDrawer-paper": {
                  width: "40%",
                  padding: "20px",
                },
              }}
            >
              {selectedCheck && (
                <Box>
                  <Typography variant="h6">
                    {selectedCheck.check_name}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {selectedCheck.check_description}
                  </Typography>
                  <Box display="flex" justifyContent="flex-end" mb={2}>
                    <Button
                      variant="contained"
                      disabled={riskAssessmentDetail?.is_submitted}
                      onClick={handleUploadButtonClick}
                      style={{
                        marginBottom: "10px",
                        marginRight: "10px",
                        background: riskAssessmentDetail?.is_submitted
                          ? "#eeeeee"
                          : "#001b2b",
                        color: riskAssessmentDetail?.is_submitted
                          ? "#808080"
                          : "white",
                      }}
                    >
                      Upload
                    </Button>
                  </Box>

                  {uploadFormOpen && (
                    <Box
                      component="form"
                      onSubmit={handleFormSubmit}
                      sx={{ mt: 2 }}
                    >
                      <TextField
                        name="sub_check_name"
                        label="Artifact Name"
                        variant="outlined"
                        fullWidth
                        required
                        value={formValues.sub_check_name}
                        onChange={handleFormChange}
                        sx={{ mb: 2 }}
                      />
                      {fileWarning && (
                        <Alert severity="warning" sx={{ mb: 2 }}>
                          {fileWarning}
                        </Alert>
                      )}
                      <TextField
                        type="file"
                        inputRef={fileInput}
                        fullWidth
                        required
                        onChange={(e) => handleFileUpload(e.target.files[0])}
                        sx={{ mb: 2 }}
                      />
                      <TextField
                        name="comment"
                        label="Comment"
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={3}
                        value={formValues.comment}
                        onChange={handleFormChange}
                        sx={{ mb: 2 }}
                      />
                      <TextField
                        name="date_of_expiry"
                        label="Date of Expiry"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                          min: minExpiryDate.toISOString().split("T")[0],
                        }}
                        fullWidth
                        required
                        value={formValues.date_of_expiry}
                        onChange={handleFormChange}
                        sx={{ mb: 2 }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={acknowledgmentChecked}
                            onChange={(e) =>
                              setAcknowledgmentChecked(e.target.checked)
                            }
                            name="acknowledgment"
                            color="primary"
                          />
                        }
                        label="I acknowledge that the evidence uploaded is within the 1-year range and it is from my own organization."
                        sx={{ mb: 2 }}
                      />
                      <Box display="flex" justifyContent="flex-end">
                        <Button
                          onClick={handleCancel}
                          variant="contained"
                          style={{
                            background: "#eeeeee",
                            color: "#808080",
                            marginRight: "10px",
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          type="submit"
                          disabled={uploading || !acknowledgmentChecked}
                          style={{
                            background:
                              uploading || !acknowledgmentChecked
                                ? "#aaaaaa"
                                : "#001b2b",
                            color: "#ffffff",
                          }}
                        >
                          Submit
                        </Button>
                      </Box>
                    </Box>
                  )}
                  {subChecks.length ? (
                    subChecks.map((subCheck, index) => (
                      <Box
                        key={subCheck.sub_check_id}
                        style={{ margin: "10px 0" }}
                      >
                        <Typography variant="h6">{`${index + 1}: ${
                          subCheck.sub_check_name
                        }`}</Typography>
                        {subCheck.artifacts && subCheck.artifacts.length ? (
                          subCheck.artifacts.map((artifact, artIndex) => (
                            <Box
                              key={artIndex}
                              display="flex"
                              flexDirection="column"
                              style={{
                                background: "#f0f0f0",
                                padding: "10px",
                                borderRadius: "5px",
                                margin: "10px 0",
                              }}
                            >
                              <Typography variant="body2" color="textSecondary">
                                Comment: {artifact.comment || "No comment"}
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                                Date of Capture:{" "}
                                {new Date(
                                  artifact.date_of_capture
                                ).toLocaleDateString() || "N/A"}
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                                Date of Expiry:{" "}
                                {new Date(
                                  artifact.date_of_expiry
                                ).toLocaleDateString() || "N/A"}
                              </Typography>
                              <Button
                                variant="outlined"
                                onClick={() =>
                                  handleOpenArtifactModal(
                                    artifact.artifact_path
                                  )
                                }
                                style={{
                                  marginTop: "10px",
                                  background: "#eeeeee",
                                  color: "#808080",
                                }}
                              >
                                Open
                              </Button>
                            </Box>
                          ))
                        ) : (
                          <Typography>
                            No artifacts found for this sub-check.
                          </Typography>
                        )}
                        <Button
                          variant="contained"
                          disabled={
                            riskAssessmentDetail?.is_submitted ? true : false
                          }
                          onClick={() => {
                            setSelectedSubCheck(subCheck);
                            setArtifactFormVisible(subCheck.sub_check_id); // Set the form visible state to the current sub-check id
                          }}
                          style={{
                            marginTop: "10px",
                            background: "#eeeeee",
                            color: "#808080",
                          }}
                        >
                          Add Artifact
                        </Button>
                        {artifactFormVisible === subCheck.sub_check_id && (
                          <Box
                            component="form"
                            onSubmit={handleAddDocument}
                            sx={{ mt: 2 }}
                          >
                            {fileWarning && (
                              <Alert severity="warning" sx={{ mb: 2 }}>
                                {fileWarning}
                              </Alert>
                            )}
                            <TextField
                              type="file"
                              inputRef={fileInput}
                              fullWidth
                              required
                              onChange={(e) =>
                                handleFileUpload(e.target.files[0])
                              }
                              sx={{ mb: 2 }}
                            />
                            <TextField
                              name="comment"
                              label="Comment"
                              variant="outlined"
                              fullWidth
                              multiline
                              rows={3}
                              value={formValues.comment}
                              onChange={handleFormChange}
                              sx={{ mb: 2 }}
                            />
                            <TextField
                              name="date_of_expiry"
                              label="Date of Expiry"
                              type="date"
                              InputLabelProps={{ shrink: true }}
                              inputProps={{
                                min: minExpiryDate.toISOString().split("T")[0],
                              }}
                              fullWidth
                              required
                              value={formValues.date_of_expiry}
                              onChange={handleFormChange}
                              sx={{ mb: 2 }}
                            />
                            <Box display="flex" justifyContent="flex-end">
                              <Button
                                onClick={() => setArtifactFormVisible(null)} // Close the form on cancel
                                variant="contained"
                                style={{
                                  background: "#eeeeee",
                                  color: "#808080",
                                  marginRight: "10px",
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                variant="contained"
                                type="submit"
                                disabled={uploading}
                                style={{
                                  background: "#eeeeee",
                                  color: "#808080",
                                }}
                              >
                                Submit
                              </Button>
                            </Box>
                          </Box>
                        )}
                        <Divider style={{ margin: "10px 0" }} />
                      </Box>
                    ))
                  ) : (
                    <Typography>No Artifacts found.</Typography>
                  )}
                </Box>
              )}
            </Drawer>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div style={{ width: "100%", height: "100%" }}>
            <RiskRegister riskAssessmentId={RiskAssessmentId} />
          </div>
        </TabPanel>
      </Box>
      <Modal
        open={openModal}
        onClose={handleCloseArtifactModal}
        aria-labelledby="artifact-modal-title"
        aria-describedby="artifact-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: isFullscreen ? "100%" : "80%",
            height: isFullscreen ? "100%" : "80%",
            maxWidth: "100vw",
            maxHeight: "100vh",
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 3,
            p: 4,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <IconButton
            onClick={handleCloseArtifactModal}
            sx={{ position: "absolute", top: 10, right: 10 }}
          >
            <CloseIcon />
          </IconButton>
          <IconButton
            onClick={handleToggleFullscreen}
            sx={{ position: "absolute", top: 10, right: 50 }}
          >
            {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </IconButton>
          <Typography id="artifact-modal-title" variant="h6" component="h2">
            Artifact Viewer
          </Typography>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              mt: 2,
              overflow: "auto",
              flexGrow: 1,
            }}
          >
            {renderFileViewer(artifactLink)}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default RiskRequirements;
